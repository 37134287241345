import axios from 'common/axios';
import {
  API_KEY,
  AUTH_URL,
  SLUG_SIGN_IN,
  SLUG_SIGN_UP,
} from '../../common/axiosSettings';
import { AUTHORIZATION } from '../../common/constants';
import * as action from './actionTypes';

export const openLoginScreen = () => {
  return {
    type: action.SET_MANY,
    payload: { openedLoginScreen: true },
  };
};

export const closeLoginScreen = () => {
  return {
    type: action.SET_MANY,
    payload: { openedLoginScreen: false },
  };
};

export const logout = message => {
  return dispatch => {
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userID');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('authorization');
    localStorage.removeItem('defaultTeam');
    localStorage.removeItem('name');
    localStorage.removeItem('surname');
    localStorage.removeItem('openedLoginScreen');
    localStorage.removeItem('afterReg');
    dispatch(logoutNext(message));
    setTimeout(() => {
      dispatch(deleteLogoutMessage());
    }, 2500);
  };
};

export const logoutNext = message => {
  return {
    type: action.SET_MANY,
    payload: {
      token: '',
      userEmail: '',
      userID: '',
      expirationDate: '',
      authorization: [],
      defaultTeam: '',
      name: '',
      surname: '',
      playerID: '',
      loading: false,
      logoutMessage: message,
      loginCompleted: false,
    },
  };
};

export const deleteLogoutMessage = () => {
  return {
    type: action.SET_MANY,
    payload: { logoutMessage: '' },
  };
};

export const setAuthTimeout = expiresIn => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout('Prebehlo odhlásenie po 1 hodine'));
    }, expiresIn * 1000);
  };
};

export const authStart = () => {
  return {
    type: action.SET_MANY,
    payload: { loading: true, afterReg: false },
  };
};

export const reportCompleted = () => {
  return {
    type: action.SET_MANY,
    payload: { reportCompleted: true },
  };
};

export const loginCompleted = () => {
  return {
    type: action.SET_MANY,
    payload: { loginCompleted: true },
  };
};

export const refreshAuth = () => {
  return dispatch => {
    let refreshData = {};
    refreshData.idToken = localStorage.getItem('token');
    refreshData.localId = localStorage.getItem('userID');
    if (!refreshData.idToken || !localStorage.getItem('userID')) {
      dispatch(logout());
      return;
    }
    refreshData.expiresIn =
      (new Date(localStorage.getItem('expirationDate')).getTime() -
        new Date().getTime()) /
      1000;
    if (
      new Date().getTime() <
      new Date(localStorage.getItem('expirationDate')).getTime() - 60000
    ) {
      dispatch(refreshAuthNext(refreshData));
    } else {
      dispatch(logout('Prebehlo odhlásenie po 1 hodine'));
    }
  };
};

export const refreshAuthNext = refreshData => {
  return dispatch => {
    dispatch(authSuccess(refreshData, true));
    dispatch(setAuthTimeout(refreshData.expiresIn));
  };
};

export const refreshAuthStart = authData => {
  return {
    type: action.SET_MANY,
    payload: {
      token: authData.idToken,
      userEmail: authData.email,
      userID: authData.localId,
      playerID: authData.playerID,
      expirationDate: authData.expirationDate,
      authorization: authData.authorization,
      defaultTeam: authData.defaultTeam,
      name: authData.name,
      surname: authData.surname,
      loading: false,
      errorMessage: '',
      afterReg: authData.afterReg,
      openedLoginScreen: authData.openedLoginScreen,
    },
  };
};

export const authSuccess = (responseData, isRefreshing) => {
  return dispatch => {
    const expirationDate = new Date(
      new Date().getTime() + responseData.expiresIn * 1000
    );
    if (!isRefreshing) {
      localStorage.setItem('token', responseData.idToken);
      localStorage.setItem('userEmail', responseData.email);
      localStorage.setItem('userID', responseData.localId);
    } else {
      responseData.idToken = localStorage.getItem('token');
      responseData.email = localStorage.getItem('userEmail');
      responseData.localId = localStorage.getItem('userID');
    }
    localStorage.setItem('expirationDate', expirationDate);
    const queryParams = `?auth=${responseData.idToken}&orderBy="userID"&equalTo="${responseData.localId}"`;
    axios.get(`users.json${queryParams}`).then(res => {
      if (!Object.keys(res.data).length) {
        dispatch(logout());
        return;
      }
      const id = Object.keys(res.data)[0];

      const authorizationList = [];
      if (res.data[id]?.authorization) {
        authorizationList.push(res.data[id]?.authorization);
      }

      localStorage.setItem('playerID', res.data[id].playerID);
      localStorage.setItem('name', res.data[id].name);
      localStorage.setItem('surname', res.data[id].surname);
      localStorage.setItem('defaultTeam', res.data[id].defaultTeam);
      responseData.expirationDate = expirationDate;
      responseData.authorization = authorizationList;
      responseData.playerID = res.data[id].playerID;
      responseData.name = res.data[id].name;
      responseData.surname = res.data[id].surname;
      responseData.defaultTeam = res.data[id].defaultTeam;
      dispatch(setAuthTimeout(responseData.expiresIn));
      dispatch(closeLoginScreenTimeout());
      dispatch(authSuccessNext(responseData));
    });
  };
};

export const authSuccessNext = responseData => {
  return {
    type: action.SET_MANY,
    payload: {
      token: responseData.idToken,
      userEmail: responseData.email,
      userID: responseData.localId,
      playerID: responseData.playerID,
      expirationDate: responseData.expirationDate,
      authorization: responseData.authorization,
      defaultTeam: responseData?.defaultTeam,
      name: responseData.name,
      surname: responseData.surname,
      loading: false,
      errorMessage: '',
      regSuccessMessage: '',
    },
  };
};

export const authFail = error => {
  let message = error?.response?.data?.error?.message;
  let errorMessage = '';
  if (message === 'EMAIL_EXISTS') {
    errorMessage = 'Účet s týmto emailom už existuje';
  } else if (message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
    errorMessage = 'Príliš veľa pokusov, skús to neskôr';
  } else if (message === 'EMAIL_NOT_FOUND') {
    errorMessage = 'Takýto účet neexistuje. Môžeš si ho vytvoriť';
  } else if (message === 'INVALID_EMAIL') {
    errorMessage = 'Zle zadaný email';
  } else if (message === 'INVALID_PASSWORD') {
    errorMessage = 'Zadal si zlé heslo';
  } else if (message === 'USER_DISABLED') {
    errorMessage = 'Účet bol zablokovaný';
  } else if (error?.message === 'Network Error') {
    errorMessage = 'Nie si pripojený k internetu';
  } else {
    errorMessage = `Chyba: ${message}`;
  }
  return {
    type: action.SET_MANY,
    payload: { errorMessage: errorMessage, loading: false },
  };
};

export const auth = userData => {
  return dispatch => {
    dispatch(authStart());
    const data = {
      email: userData.email,
      password: userData.password,
      returnSecureToken: true,
    };
    const URL_SLUG = userData.type === 'signIn' ? SLUG_SIGN_IN : SLUG_SIGN_UP;
    const url = `${AUTH_URL}${URL_SLUG}${API_KEY}`;
    axios
      .post(url, data)
      .then(response => {
        if (userData.type === 'signIn') {
          dispatch(authSuccess(response.data));
        }
        if (userData.type === 'signUp') {
          dispatch(regSuccess(response.data));
        }
      })
      .catch(error => {
        dispatch(authFail(error));
      });
  };
};

export const regSuccess = responseData => {
  localStorage.setItem('afterReg', true);
  localStorage.setItem('openedLoginScreen', true);
  localStorage.setItem('token', responseData.idToken);
  localStorage.setItem('userID', responseData.localId);
  localStorage.setItem('userEmail', responseData.email);
  const expirationDate = new Date(
    new Date().getTime() + responseData.expiresIn * 1000
  );
  localStorage.setItem('expirationDate', expirationDate);
  responseData.expirationDate = expirationDate;
  return {
    type: action.SET_MANY,
    payload: {
      loading: false,
      afterReg: true,
      errorMessage: '',
      token: responseData.idToken,
      userID: responseData.localId,
      userEmail: responseData.email,
    },
  };
};
export const afterRegSuccess = responseData => {
  localStorage.removeItem('afterReg');
  localStorage.removeItem('openedLoginScreen');
  localStorage.removeItem('token');
  localStorage.removeItem('userID');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('expirationDate');
  return {
    type: action.SET_MANY,
    payload: {
      loading: false,
      afterReg: false,
      regSuccess: true,
      token: '',
      userID: '',
      userEmail: '',
      errorMessage: '',
      expirationDate: '',
      regSuccessMessage: 'Teraz sa môžeš prihlásiť',
    },
  };
};

export const switchRegSuccess = () => {
  return {
    type: action.SET_MANY,
    payload: {
      regSuccess: true,
      afterReg: false,
      loading: false,
    },
  };
};

export const switchToSigningInTimeout = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(signInTogglerAfterReg());
      dispatch(switchRegSuccess());
    }, 700);
  };
};

export const signInToggler = () => {
  return {
    type: action.SIGN_IN_TOGGLER,
  };
};
export const signInTogglerAfterReg = () => {
  return {
    type: action.SIGN_IN_TOGGLER_AFTER_REG,
  };
};

export const signInOpenerTimeout = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(signInOpener());
    }, 1000);
  };
};

export const signInOpener = () => {
  return {
    type: action.SET_MANY,
    payload: { signIn: true, regSuccess: false },
  };
};

export const closeLoginScreenTimeout = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(closeLoginScreen());
      dispatch(loginCompleted());
    }, 200);
  };
};

export const postRegAuth = userData => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    const email = getState().auth.userEmail;
    const userID = getState().auth.userID;
    dispatch(authStart());
    let data = {
      userID: userID,
      email: email,
      name: userData.name,
      surname: userData.surname,
      defaultTeam: userData.defaultTeam,
      playerID: '',
      authorization: '',
    };
    axios
      .post(`users/.json?auth=${token}`, data)
      .then(response => {
        dispatch(afterRegSuccess(response.data));
        dispatch(signInOpenerTimeout());
      })
      .catch(error => {
        dispatch(authFail(error));
      });
  };
};
