import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

import { genericSort } from 'common/utils';
import { mediaQueries } from 'common/theme';
import Button from 'components/UI/Button';
import { Box, Row } from 'components/BasicComponents';
import Input from 'components/UI/Input';

import GoalRow from 'pages/Matches/GoalRow';

const MatchDetailGoals = ({
  data,
  postHandler,
  deleteHandler,
  postEditedGoal,
  canEdit,
  playerID,
}) => {
  const { t } = useTranslation();

  const [editable, setEditable] = useState('');
  const [newHomeGoalIndexes, setNewHomeGoalIndexes] = useState({
    goal: '',
    assist: '',
  });
  const [newAwayGoalIndexes, setNewAwayGoalIndexes] = useState({
    goal: '',
    assist: '',
  });
  const [editIndexes, setEditIndexes] = useState({
    goal: '',
    assist: '',
  });

  const { homeTeamGoals, awayTeamGoals, homeTeamRoster, awayTeamRoster } = data;

  const sortedHomeTeam = genericSort(
    cloneDeep(homeTeamRoster),
    false,
    'text',
    'name'
  );
  const sortedAwayTeam = genericSort(
    cloneDeep(awayTeamRoster),
    false,
    'text',
    'name'
  );

  const changeHandler = (e, team) => {
    const newData = {
      [e.target.name]: e.target.value ? +e.target.value : '',
    };
    if (team === 'home') {
      setNewHomeGoalIndexes(oldIndexes => ({ ...oldIndexes, ...newData }));
    } else {
      setNewAwayGoalIndexes(oldIndexes => ({ ...oldIndexes, ...newData }));
    }
  };

  const thisPostHandler = team => {
    const sourceTeam = team === 'home' ? sortedHomeTeam : sortedAwayTeam;
    const sourceIndexes =
      team === 'home' ? newHomeGoalIndexes : newAwayGoalIndexes;
    const goalIndexIsNull = sourceIndexes.goal === '';
    const assistIndexIsNull = sourceIndexes.assist === '';

    const goalPlayer = goalIndexIsNull ? '' : sourceTeam[sourceIndexes.goal];
    const assistPlayer = assistIndexIsNull
      ? ''
      : sourceTeam[sourceIndexes.assist];

    const dataGoalPlayer = {
      playerID: goalPlayer?.playerID || '',
      name: goalPlayer?.name || '',
    };
    const dataAssistPlayer = {
      playerID: assistPlayer?.playerID || '',
      name: assistPlayer?.name || '',
    };

    const goal = {
      goal: dataGoalPlayer,
      assist: dataAssistPlayer,
    };

    postHandler(team, goal);
  };

  const thisPostEditedHandler = (team, goalID) => {
    const sourceTeam = team === 'home' ? sortedHomeTeam : sortedAwayTeam;
    const goalIndexIsNull = editIndexes.goal === '';
    const assistIndexIsNull = editIndexes.assist === '';

    const goalPlayer = goalIndexIsNull ? '' : sourceTeam[editIndexes.goal];
    const assistPlayer = assistIndexIsNull
      ? ''
      : sourceTeam[editIndexes.assist];

    const dataGoalPlayer = {
      playerID: goalPlayer?.playerID || '',
      name: goalPlayer?.name || '',
    };
    const dataAssistPlayer = {
      playerID: assistPlayer?.playerID || '',
      name: assistPlayer?.name || '',
    };

    const goal = {
      goal: dataGoalPlayer,
      assist: dataAssistPlayer,
      id: goalID,
    };

    postEditedGoal(team, goal);
    setEditable('');
    setEditIndexes({
      goal: '',
      assist: '',
    });
  };

  const blocks = [
    {
      name: 'home',
      goals: homeTeamGoals,
      roster: sortedHomeTeam,
      newValue: newHomeGoalIndexes,
      sortedTeam: sortedHomeTeam,
    },
    {
      name: 'away',
      type: 'red',
      goals: awayTeamGoals,
      roster: sortedAwayTeam,
      newValue: newAwayGoalIndexes,
      sortedTeam: sortedAwayTeam,
    },
  ];

  return (
    <MatchDetailStyled>
      {blocks.map(block => (
        <TeamStyled key={block.name}>
          <BoxStyled>
            {block.goals.map((goal, i) => (
              <GoalRow
                key={goal.id}
                data={goal}
                index={i}
                redButton={() =>
                  editable === goal.id
                    ? setEditable('')
                    : deleteHandler(block.name, goal.id)
                }
                blueButton={() =>
                  editable === goal.id
                    ? thisPostEditedHandler(block.name, goal.id)
                    : setEditable(goal.id)
                }
                isEditable={editable === goal.id}
                canEdit={canEdit}
                team={block.name}
                sortedTeam={block.sortedTeam}
                editIndexes={editIndexes}
                setEditIndexes={setEditIndexes}
                playerID={playerID}
              />
            ))}
          </BoxStyled>
          {canEdit && (
            <BoxStyled>
              <RowStyled>
                {t('Gól')}
                <Input
                  type='select'
                  name='goal'
                  value={block.newValue.goal}
                  onChange={e => changeHandler(e, block.name)}
                  width='13.5rem'
                  height='3rem'
                >
                  <option value={''}>{t('Neznámy')}</option>
                  {block.roster.map((player, i) => (
                    <option key={`${player.id}${i}`} value={i}>
                      {player.name}
                    </option>
                  ))}
                </Input>
              </RowStyled>
              <RowStyled>
                {t('Asistencia')}
                <Input
                  type='select'
                  name='assist'
                  value={block.newValue.assist}
                  onChange={e => changeHandler(e, block.name)}
                  width='13.5rem'
                  height='3rem'
                >
                  <option value={''}>{t('Bez asistencie')}</option>
                  {block.roster.map((player, i) => (
                    <option key={`${player.id}${i}`} value={i}>
                      {player.name}
                    </option>
                  ))}
                </Input>
              </RowStyled>
              <Button
                icon='plus'
                type='greenFull'
                text={t('Pridať')}
                onClick={() => thisPostHandler(block.name)}
              />
            </BoxStyled>
          )}
        </TeamStyled>
      ))}
    </MatchDetailStyled>
  );
};

export default MatchDetailGoals;

const MatchDetailStyled = styled(Row)`
  width: 100%;
`;
const BoxStyled = styled(Box)`
  padding-top: 1rem;
`;
const RowStyled = styled(Row)`
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.s} {
    flex-direction: column;
  }
`;
const TeamStyled = styled(Box)`
  width: 50%;
  align-items: center;
`;
