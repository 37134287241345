import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Box } from 'components/BasicComponents';
import logo from 'assets/images/logo_BHL_white.svg';

const Logo = ({ mini, handleInstall }) => {
  return <LogoWrapperStyled mini={mini} onClick={() => handleInstall()} />;
};

export default Logo;

const LogoWrapperStyled = styled(Box)`
  background: url(${logo}) no-repeat center center;
  background-size: contain;
  height: 100%;
  width: 10rem;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  transform: ${props => (props.mini ? 'scale(0.8)' : 'scale(1)')};
  @media ${mediaQueries.l} {
    transform: scale(0.8);
  }
  @media ${mediaQueries.m} {
    min-width: 4rem;
    width: 4rem;
    height: 100%;
    margin-right: 1rem;
    transform: scale(1);
  }
`;
