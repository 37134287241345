import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { arenas } from 'common/constants';
import { mediaQueries } from 'common/theme';
import { formatISODate } from 'common/utils';
import Jersey from 'components/Jersey';
import { Icons } from 'common/Icons';
import Button from 'components/UI/Button';
import { Row } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import { MatchDetail } from 'pages/Matches/MatchDetail';
import MatchDetailGoals from 'pages/Matches/MatchDetailGoals';
import SwitchRow from 'pages/Matches/SwitchRow';

export const OldMatch = ({
  data,
  isActual,
  isEditable,
  isSelected,
  selectHandler,
  changeHandler,
  deleteHandler,
  editHandler,
  saveHandler,
  canEdit,
  isPlayer,
  showRoster,
  setShowRoster,
  playerID,
  users,
  addPlayerHandler,
  deletePlayerHandler,
  moveHandler,
  loading,
  errorMessage,
  postHandler,
  postEditedGoal,
  deleteGoalHandler,
  secondaryRegHandler,
  secondaryDeleteHandler,
}) => {
  const { t } = useTranslation();

  const {
    date,
    time,
    arena,
    scoreHome,
    scoreAway,
    totalPrice,
    maxPlayers,
    homeTeamRoster,
    awayTeamRoster,
  } = data;

  const playersTotal = homeTeamRoster.length + awayTeamRoster.length;

  return (
    <MatchStyled
      isActual={isActual}
      isEditable={isEditable}
      onClick={selectHandler}
      isSelected={isSelected}
    >
      <RowStyled>
        <MatchColumnStyled>
          {isEditable ? (
            <Input
              name='date'
              type='date'
              value={date}
              onChange={e => changeHandler(e)}
              height='2.5rem'
              width='12rem'
            />
          ) : (
            <>
              <i className={Icons.calendar} />
              {formatISODate(date)}
            </>
          )}
        </MatchColumnStyled>
        <MatchColumnStyled hide={!isEditable}>
          {isEditable ? (
            <Input
              name='time'
              type='time'
              value={time}
              onChange={e => changeHandler(e)}
              height='2.5rem'
              width='9rem'
            />
          ) : (
            <>
              <i className={Icons.clock} />
              {time}
            </>
          )}
        </MatchColumnStyled>
        <MatchColumnStyled hide={!isEditable}>
          <i className={Icons.mapmarker} />
          {isEditable ? (
            <Input
              type='select'
              name='arena'
              value={arena}
              onChange={e => changeHandler(e)}
              height='3rem'
            >
              {arenas.map(arena => (
                <option>{arena.name}</option>
              ))}
            </Input>
          ) : (
            arena
          )}
        </MatchColumnStyled>
      </RowStyled>
      <RowStyled>
        {isEditable && (
          <MatchColumnStyled>
            <Input
              name='totalPrice'
              placeholder={t('Cena')}
              maxLength='4'
              type='text'
              value={totalPrice}
              onChange={e => changeHandler(e)}
              height='2.5rem'
              width='5.5rem'
              label='€ (odhad)'
            />
          </MatchColumnStyled>
        )}
        <MatchColumnStyled>
          <Jersey size='2rem' />
          <ScoreRowStyled>
            {isEditable ? (
              <>
                <Input
                  name='scoreHome'
                  maxLength='2'
                  type='text'
                  value={scoreHome}
                  onChange={e => changeHandler(e)}
                  height='2.5rem'
                  width='4rem'
                />
                :
                <Input
                  name='scoreAway'
                  maxLength='2'
                  type='text'
                  value={scoreAway}
                  onChange={e => changeHandler(e)}
                  height='2.5rem'
                  width='4rem'
                />
              </>
            ) : (
              `${scoreHome} : ${scoreAway}`
            )}
          </ScoreRowStyled>
          <Jersey type='red' size='2rem' />
        </MatchColumnStyled>
        <MatchColumnStyled hide={!isEditable}>
          {isEditable ? (
            <>
              {`${playersTotal} /`}
              <Input
                name='maxPlayers'
                maxLength='2'
                type='text'
                value={maxPlayers}
                onChange={e => changeHandler(e)}
                height='2.5rem'
                width='4rem'
              />
              {t('max')}
            </>
          ) : (
            `${playersTotal} / ${maxPlayers}`
          )}
        </MatchColumnStyled>

        {canEdit && (
          <>
            <Button
              type={isEditable ? 'green' : 'blue'}
              icon={isEditable ? 'save' : 'edit'}
              onClick={() => (isEditable ? saveHandler() : editHandler(true))}
            />
            <Button
              type={'red'}
              icon={isEditable ? 'cancel' : 'delete'}
              onClick={() =>
                isEditable ? editHandler(false) : deleteHandler()
              }
            />
          </>
        )}
      </RowStyled>
      {isSelected && (
        <SwitchRow showRoster={showRoster} setShowRoster={setShowRoster} />
      )}
      {isSelected && showRoster && (
        <MatchDetail
          data={data}
          playerID={playerID}
          canEdit={canEdit}
          isPlayer={isPlayer}
          users={users}
          addPlrHandler={addPlayerHandler}
          deleteHandler={deletePlayerHandler}
          moveHandler={moveHandler}
          loading={loading}
          errorMessage={errorMessage}
          secondaryRegHandler={secondaryRegHandler}
          secondaryDeleteHandler={secondaryDeleteHandler}
        />
      )}
      {isSelected && !showRoster && (
        <MatchDetailGoals
          data={data}
          playerID={playerID}
          canEdit={canEdit || isPlayer}
          postHandler={postHandler}
          postEditedGoal={postEditedGoal}
          deleteHandler={deleteGoalHandler}
        />
      )}
    </MatchStyled>
  );
};

const MatchStyled = styled(Row)`
  width: 100%;
  height: ${props => (props.isSelected ? '83rem' : '3.5rem')};
  background: #ffffff20;
  align-items: center;
  border-radius: 0.7rem;
  border: 1px solid
    ${props => (props.isSelected ? props.theme.colors.redLight : 'transparent')};
  position: relative;
  padding: 0.3rem 0.5rem;
  margin: 0.5rem 0;
  cursor: pointer;
  flex-direction: ${props => (props.isSelected ? 'column' : 'row')};
  transition: all 0.5s;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* &:hover {
    background: ${props =>
    props.isEditable || props.newMatch ? '' : '#FFFFFF28'};
  } */
  @media ${mediaQueries.m} {
    flex-direction: column;
  }
  @media ${mediaQueries.s} {
    height: ${props => (props.isSelected ? '93rem' : '3.5rem')};
  }
`;

const MatchColumnStyled = styled(Row)`
  align-items: center;
  padding: 0 1rem;
  display: ${props => (props.hide ? 'none' : '')};
  & > i {
    margin-right: 0.7rem;
    color: ${props => props.theme.colors.tertiary};
  }
`;

const ScoreRowStyled = styled(Row)`
  min-width: 4.5rem;
  font-weight: 700;
  font-size: 2rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
`;
const RowStyled = styled(Row)`
  padding: 0.5rem;
`;
