export const sizes = {
  xs: '400px',
  s: '600px',
  m: '800px',
  l: '1000px',
  xl: '1200px',
};

export const mediaQueries = {
  xs: `(max-width: ${sizes.xs})`,
  s: `(max-width: ${sizes.s})`,
  m: `(max-width: ${sizes.m})`,
  l: `(max-width: ${sizes.l})`,
  xl: `(min-width: ${sizes.xl})`,
};

export const theme = {
  headerHeight: {
    desktop: '7rem',
    compact: '4rem',
    mobile: '5.5rem',
  },

  headerVisibleThreshold: 80,
  headerCompactThreshold: 80,
  permanentCompactHeaderWidth: 800,

  zIndex: {
    backdrop: -1,
    header: 5,
    backdropLoginOpened: 10,
    reportWarning: 15,
    loginScreen: 20,
    backdropOpened: 21,
    sideDrawer: 120,
    modal: 500,
  },

  radius: {
    xs: '0.4rem',
    s: '0.8rem',
    m: '1rem',
    l: '1.2rem',
    xl: '1.6rem',
    xxl: '2rem',
  },

  colors: {
    white8: '#ffffffcc',
    white6: '#ffffff99',
    white4: '#ffffff66',
    white2: '#ffffff33',
    white1: '#ffffff1A',
    white0: '#ffffff0D',
    black8: '#000000cc',
    black6: '#00000099',
    black4: '#00000066',
    black2: '#00000033',
    black1: '#0000001A',
    black0: '#0000000D',
    modal: '#f5f5f5b3',
    logo: '#19213f',
    header: '#f6f7f880',
    heading: '#0e2f55',
    menu: '#323d73',
    primary: '#171717',
    secondary: '#191919',
    white: '#EDEDED',
    tertiary: '#DA0037',
    errorDark: '#e72e2e',
    error: '#f67273',
    errorLight: '#f6727350',
    errorText: '#fff',
    warning: '#FFD090',
    warningLight: '#ffbc0080',
    warningText: '#382900',
    red: '#FF7870',
    redLight: '#FF787090',
    green: '#E5FF6A',
    greenLight: '#E5FF6A40',
    successLight: '#AFE16680',
    successText: '#223702',
    userIcon: '#1caace',
    sort: '#40AFFF',
    blue: '#82CBFF',
    editable: { background: '#d4f1d4', color: '#40b946' },
    active: { primary: '#a8e063cc', secondary: '#a8e06366' },
    progress: '#ff8008',
    progressCompleted: '#52b56a',

    buttons: {
      gray: { light: '#606066', dark: '#75757c' },
      black: { light: '#272729', dark: '#171718' },
      red: {
        light: '#FF7870',
        dark: '#FF392D',
        color: '#000',
        hoverColor: '#000',
      },
      green: {
        light: '#E5FF6A',
        dark: '#D7F73B',
        color: '#000',
        hoverColor: '#000',
      },
      blue: {
        light: '#82CBFF',
        dark: '#40AFFF',
        color: '#000',
        hoverColor: '#000',
      },
      orange: {
        light: '#FFD090',
        dark: '#FFAE40',
        color: '#000',
        hoverColor: '#000',
      },
      yellow: {
        light: '#FFEE90',
        dark: '#FFE13A',
        color: '#000',
        hoverColor: '#000',
      },
      purple: { light: '#8f94fb', dark: '#4e54c8' },
      disabled: {
        light: '#92929216',
        dark: '#92929216',
        color: '#92929277',
        hoverColor: '#92929277',
      },
      close: { light: '#ef473a', dark: '#ef473a' },
    },

    authorization: {
      host: 'rgba(255, 255, 255, 0.4)',
      player: '#E5FF6A',
      admin: '#40AFFF',
      owner: '#FF7870',
    },

    fonts: {
      body: 'BHLPrompt, calibri, tahoma',
      heading: 'BHLPrompt, calibri, tahoma',
    },
    fontWeights: {
      normal: 400,
      medium: 600,
      bold: 800,
    },
  },
};
