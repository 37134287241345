import React, { useState, useEffect, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import axios from 'common/axios';
import Loading from 'components/UI/Loading';
import { isOldDay, genericSort } from 'common/utils';
import Jersey from 'components/Jersey';
import * as actionCreators from 'store/actions';
import ProgressCircle from 'components/UI/ProgressCircle';
import ColorCircle from 'components/UI/ColorCircle';
import { Box, Row, MessageBox } from 'components/BasicComponents';

import User from 'pages/owner/Users/User';

const Stats = props => {
  const { t } = useTranslation();

  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [sortDescending, setSortDescending] = useState(false);
  const [sortType, setSortType] = useState('text');
  const [sortParameter, setSortParameter] = useState('surname');

  const setSortHandler = type => {
    if (type === sortParameter) {
      setSortDescending(sortDescending => !sortDescending);
      return;
    } else {
      setSortDescending(true);
      setSortParameter(type);
    }
    if (type === 'surname') {
      setSortType('text');
    } else {
      setSortType('number');
    }
    setSortParameter(type);
  };

  const matchesLength = matches.length;

  const token = props.auth.token;

  const loadUsers = useCallback(
    newMatches => {
      axios
        .get(`users.json?auth=${token}`)
        .then(response => {
          if (!response.data) {
            setLoading(false);
          }
          if (!!response.data) {
            const newUsers = [];
            const gamesTotal = newMatches?.length;
            for (let user in response.data) {
              const dataPlayerID = response.data[user].playerID;
              const filteredGamesPlayed = newMatches.filter(match =>
                match.rosters.some(plrID => plrID === dataPlayerID)
              );
              let wonMatches = 0;
              let lostMatches = 0;
              let drawnMatches = 0;
              filteredGamesPlayed.forEach(match => {
                if (match.result === 0) {
                  drawnMatches += 1;
                } else {
                  const wasInHomeTeam = match.homeRoster.includes(dataPlayerID);
                  const wasInAwayTeam = match.awayRoster.includes(dataPlayerID);
                  if (match.result === 1) {
                    if (wasInHomeTeam) {
                      wonMatches += 1;
                    }
                    if (wasInAwayTeam) {
                      lostMatches += 1;
                    }
                  }
                  if (match.result === 2) {
                    if (wasInHomeTeam) {
                      lostMatches += 1;
                    }
                    if (wasInAwayTeam) {
                      wonMatches += 1;
                    }
                  }
                }
              });
              const gamesPlayed = filteredGamesPlayed.length;
              const attendance = +((gamesPlayed / gamesTotal) * 100).toFixed(0);
              let goals = 0;
              let assists = 0;
              let wantedMatches = newMatches?.filter(
                match =>
                  match.goals.some(goal => goal === dataPlayerID) ||
                  match.assists.some(assist => assist === dataPlayerID)
              );

              if (wantedMatches?.length) {
                wantedMatches.forEach(match => {
                  match.goals.forEach(goal => {
                    if (goal === dataPlayerID) {
                      goals += 1;
                    }
                  });
                  match.assists.forEach(assist => {
                    if (assist === dataPlayerID) {
                      assists += 1;
                    }
                  });
                });
              }

              const points = goals + assists;
              const pointsPerGame = gamesPlayed
                ? (points / gamesPlayed).toFixed(1)
                : '0.0';

              newUsers.push({
                name: response.data[user].name,
                surname: response.data[user].surname,
                playerID: response.data[user].playerID,
                defaultTeam: response.data[user].defaultTeam,
                jerseyNumber: response.data[user].jerseyNumber || '00',
                attendance: attendance,
                gamesPlayed: gamesPlayed,
                goals: goals,
                assists: assists,
                points: points,
                pointsPerGame: pointsPerGame,
                wins: wonMatches,
                loses: lostMatches,
                draws: drawnMatches,
                id: user,
              });
            }
            setLoading(false);
            setUsers(newUsers);
            setMatches(newMatches);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    [token]
  );

  const loadMatches = useCallback(() => {
    setLoading(true);
    axios
      .get('matches.json')
      .then(response => {
        if (!response.data) {
          setLoading(false);
          return;
        } else {
          const newMatches = [];
          for (let item in response.data) {
            if (isOldDay(response.data[item].date)) {
              const homeRoster = [];
              const awayRoster = [];

              const newGoals = [];
              const newAssists = [];

              const wasDraw =
                +response.data[item].scoreHome ===
                +response.data[item].scoreAway;
              const homeTeamWon =
                +response.data[item].scoreHome > +response.data[item].scoreAway;
              const result = wasDraw ? 0 : homeTeamWon ? 1 : 2;

              if (response.data[item].homeTeamRoster) {
                for (let player in response.data[item].homeTeamRoster) {
                  if (response.data[item].homeTeamRoster[player].playerID) {
                    homeRoster.push(
                      response.data[item].homeTeamRoster[player].playerID
                    );
                  }
                }
              }
              if (response.data[item].awayTeamRoster) {
                for (let player in response.data[item].awayTeamRoster) {
                  if (response.data[item].awayTeamRoster[player].playerID) {
                    awayRoster.push(
                      response.data[item].awayTeamRoster[player].playerID
                    );
                  }
                }
              }
              if (
                response.data[item]?.homeTeamGoals &&
                Object.keys(response.data[item]?.homeTeamGoals)?.length
              ) {
                for (let goal in response.data[item].homeTeamGoals) {
                  if (response.data[item].homeTeamGoals[goal].goal.playerID) {
                    newGoals.push(
                      response.data[item].homeTeamGoals[goal].goal.playerID
                    );
                  }
                  if (response.data[item].homeTeamGoals[goal].assist) {
                    newAssists.push(
                      response.data[item].homeTeamGoals[goal].assist.playerID
                    );
                  }
                }
              }
              if (
                response.data[item]?.awayTeamGoals &&
                Object.keys(response.data[item]?.awayTeamGoals)?.length
              ) {
                for (let goal in response.data[item].awayTeamGoals) {
                  if (response.data[item].awayTeamGoals[goal].goal.playerID) {
                    newGoals.push(
                      response.data[item].awayTeamGoals[goal].goal.playerID
                    );
                  }
                  if (response.data[item].awayTeamGoals[goal].assist.playerID) {
                    newAssists.push(
                      response.data[item].awayTeamGoals[goal].assist.playerID
                    );
                  }
                }
              }

              newMatches.push({
                rosters: [...homeRoster, ...awayRoster],
                homeRoster: homeRoster,
                awayRoster: awayRoster,
                goals: newGoals,
                assists: newAssists,
                scoreHome: response.data[item].scoreHome,
                scoreAway: response.data[item].scoreAway,
                date: response.data[item].date,
                result: result,
              });
            }
          }
          loadUsers(newMatches);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  }, [loadUsers]);

  useEffect(() => {
    loadMatches();
  }, [loadMatches]);

  const usersList = genericSort(
    users,
    sortDescending,
    sortType,
    sortParameter
  ).map((user, index) => (
    <User key={user.id} data={user} index={index} isStat />
  ));

  const scoresHome = [0];
  const scoresAway = [0];

  matches.forEach(match => {
    scoresHome.push(match.scoreHome);
    scoresAway.push(match.scoreAway);
  });

  const scoreHome = scoresHome.reduce((acc, score) => +acc + +score);
  const scoreAway = scoresAway.reduce((acc, score) => +acc + +score);

  const drawsMatches = matches.filter(match => match.result === 0).length;
  const homeWinMatches = matches.filter(match => match.result === 1).length;
  const awayWinMatches = matches.filter(match => match.result === 2).length;
  const drawsPercentage = matchesLength
    ? ((drawsMatches / matchesLength) * 100).toFixed(0)
    : 0;
  const homeWinPercentage = matchesLength
    ? ((homeWinMatches / matchesLength) * 100).toFixed(0)
    : 0;
  const awayWinPercentage = matchesLength
    ? ((awayWinMatches / matchesLength) * 100).toFixed(0)
    : 0;

  const homeForm = [];
  const awayForm = [];
  const sortedMatches = matches?.length
    ? genericSort(cloneDeep(matches), true, 'date', 'date')
    : [];

  sortedMatches.slice(0, 5).forEach(match => {
    if (match.result === 0) {
      homeForm.unshift('draw');
      awayForm.unshift('draw');
    } else if (match.result === 1) {
      homeForm.unshift('win');
      awayForm.unshift('lost');
    } else if (match.result === 2) {
      homeForm.unshift('lost');
      awayForm.unshift('win');
    }
  });

  return loading ? (
    <Loading />
  ) : (
    <Box>
      <Row>
        {t('Celkovo odohraných zápasov')}:<NumStyled>{matchesLength}</NumStyled>
      </Row>
      {!!usersList.length ? (
        <>
          {
            <User
              isLabel
              isStat
              setSort={setSortHandler}
              sortParameter={sortParameter}
              sortAsc={!sortDescending}
            />
          }
          {usersList}
        </>
      ) : (
        <MessageBox warning>
          {t('Nie sú dostupné žiadne štatistiky')}
        </MessageBox>
      )}
      <StatBoxStyled>
        <StatHeadingStyled>{t('Forma')}</StatHeadingStyled>
        <StatRowStyled>
          <Jersey size='2rem' margin='0 .5rem' />

          {homeForm.map(result => (
            <ColorCircle value={result} />
          ))}
        </StatRowStyled>
        <StatRowStyled>
          <Jersey size='2rem' type='red' margin='0 .5rem' />

          {awayForm.map(result => (
            <ColorCircle value={result} />
          ))}
        </StatRowStyled>
      </StatBoxStyled>
      <StatBoxStyled>
        <StatHeadingStyled>{t('Celkové skóre')}</StatHeadingStyled>
        <StatRowStyled>
          <Jersey size='2rem' margin='0 .5rem' />
          {scoreHome}:{scoreAway}
          <Jersey size='2rem' type='red' margin='0 .5rem' />
        </StatRowStyled>
      </StatBoxStyled>
      <StatBoxStyled>
        <StatHeadingStyled>{t('Výhry')}</StatHeadingStyled>
        <StatRowStyled>
          <Jersey size='4rem' />
          <ProgressCircle
            value={homeWinPercentage}
            size='.9rem'
            textSize='1.1rem'
            margin='0.5rem'
          />
          :
          <ProgressCircle
            value={awayWinPercentage}
            size='.9rem'
            textSize='1.1rem'
            margin='0.5rem'
          />
          <Jersey size='4rem' type='red' />
        </StatRowStyled>
        <StatHeadingStyled>{t('Remízy')}</StatHeadingStyled>
        <StatRowStyled>
          <ProgressCircle
            value={drawsPercentage}
            size='.9rem'
            textSize='1.1rem'
            margin='0.5rem'
          />
        </StatRowStyled>
      </StatBoxStyled>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: (component, componentProps, title) =>
      dispatch(actionCreators.openModal(component, componentProps, title)),
    closeModal: () => dispatch(actionCreators.closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);

const NumStyled = styled(Row)`
  padding: 0rem 1rem;
  font-weight: 800;
`;
const StatBoxStyled = styled(Box)`
  padding: 1rem;
`;
const StatHeadingStyled = styled(Row)`
  min-width: 14rem;
  font-weight: 600;
`;
const StatRowStyled = styled(Row)`
  padding: 1rem;
  align-items: center;
`;
