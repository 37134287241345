import React, { useState, useEffect, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import axios from 'common/axios';
import { mediaQueries } from 'common/theme';
import { AUTHORIZATION } from 'common/constants';
import { isOldDay, formatName, genericSort } from 'common/utils';
import * as actionCreators from 'store/actions';
import Button from 'components/UI/Button';
import { Box, Row, MessageBox } from 'components/BasicComponents';
import { confirmModalAsync } from 'components/UI/ConfirmModal';
import Loading from 'components/UI/Loading';
import { VERSION } from 'pages/myAccount/Help';

import { OldMatch } from 'pages/Matches/OldMatch';
import { Match } from 'pages/Matches/Match';
import { MatchDetail } from 'pages/Matches/MatchDetail';
import MatchDetailGoals from 'pages/Matches/MatchDetailGoals';
import Chat from 'pages/Matches/Chat';
import SwitchRow from 'pages/Matches/SwitchRow';

const initialMatchState = {
  date: '',
  time: '21:30',
  maxPlayers: 22,
  arena: 'Uherský Brod',
  scoreHome: 0,
  scoreAway: 0,
  totalPrice: 135,
  homeTeamRoster: [],
  awayTeamRoster: [],
  homeTeamGoals: [],
  awayTeamGoals: [],
};

const Matches = props => {
  const { t } = useTranslation();

  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selected, setSelected] = useState('');
  const [hideOld, setHideOld] = useState(true);
  const [showRoster, setShowRoster] = useState(true);
  const [newMatch, setNewMatch] = useState(initialMatchState);

  const matchesLength = matches.length;

  const token = props.auth.token;
  const playerID = props.auth.playerID || localStorage.getItem('playerID');
  const name = props.auth.name;
  const surname = props.auth.surname;
  const isPlayer =
    token &&
    (props.auth.authorization?.includes(AUTHORIZATION.PLAYER) ||
      props.auth.authorization?.includes(AUTHORIZATION.ADMIN) ||
      props.auth.authorization?.includes(AUTHORIZATION.OWNER));
  const canEdit =
    token &&
    (props.auth.authorization?.includes(AUTHORIZATION.ADMIN) ||
      props.auth.authorization?.includes(AUTHORIZATION.OWNER));

  const futureMatches = matches.filter(match => !match.isOld);
  const oldMatches = matches.filter(match => match.isOld);

  const selectedMatch = futureMatches.length
    ? futureMatches.find(match => match.id === selected)
    : null;

  const fetchVersion = useCallback(() => {
    axios
      .get('version.json')
      .then(response => {
        return !response.data || response.data === VERSION;
      })
      .then(result => {
        if (!result) {
          window.location.reload(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const loadUsers = useCallback(() => {
    axios
      .get(`users.json?auth=${token}`)
      .then(response => {
        if (!!response.data) {
          const newUsers = [];
          for (let user in response.data) {
            newUsers.push({
              name: response.data[user].name,
              surname: response.data[user].surname,
              playerID: response.data[user].playerID,
              id: user,
            });
          }
          setUsers(newUsers);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [token]);

  const loadMatches = useCallback(() => {
    setLoading(true);
    axios
      .get('matches.json')
      .then(response => {
        const newMatches = [];
        if (!response.data) {
          setLoading(false);
          return;
        } else {
          for (let item in response.data) {
            const newHomeRoster = [];
            const newAwayRoster = [];
            const newHomeGoals = [];
            const newAwayGoals = [];
            const wontGoList = [];
            const dontKnowList = [];

            if (response.data[item].homeTeamRoster) {
              for (let player in response.data[item].homeTeamRoster) {
                newHomeRoster.push({
                  name: response.data[item].homeTeamRoster[player]?.name,
                  playerID:
                    response.data[item].homeTeamRoster[player]?.playerID,
                  isGoalie:
                    response.data[item].homeTeamRoster[player]?.isGoalie,
                  id: player,
                });
              }
            }
            if (response.data[item].awayTeamRoster) {
              for (let player in response.data[item].awayTeamRoster) {
                newAwayRoster.push({
                  name: response.data[item].awayTeamRoster[player]?.name,
                  playerID:
                    response.data[item].awayTeamRoster[player]?.playerID,
                  isGoalie:
                    response.data[item].awayTeamRoster[player]?.isGoalie,
                  id: player,
                });
              }
            }
            if (response.data[item].homeTeamGoals) {
              for (let goal in response.data[item].homeTeamGoals) {
                newHomeGoals.push({
                  goal: response.data[item].homeTeamGoals[goal].goal,
                  assist: response.data[item].homeTeamGoals[goal].assist,
                  id: goal,
                });
              }
            }
            if (response.data[item].awayTeamGoals) {
              for (let goal in response.data[item].awayTeamGoals) {
                newAwayGoals.push({
                  goal: response.data[item].awayTeamGoals[goal].goal,
                  assist: response.data[item].awayTeamGoals[goal].assist,
                  id: goal,
                });
              }
            }

            if (response.data[item]?.wontGo) {
              for (let player in response.data[item].wontGo) {
                wontGoList.push({
                  playerID: response.data[item].wontGo[player].playerID,
                  name: response.data[item].wontGo[player].name,
                  id: player,
                });
              }
            }
            if (response.data[item]?.dontKnow) {
              for (let player in response.data[item].dontKnow) {
                dontKnowList.push({
                  playerID: response.data[item].dontKnow[player].playerID,
                  name: response.data[item].dontKnow[player].name,
                  id: player,
                });
              }
            }

            const homeHasGoalie = newHomeRoster.some(plr => plr.isGoalie);
            const awayHasGoalie = newAwayRoster.some(plr => plr.isGoalie);

            newMatches.push({
              date: response.data[item].date,
              time: response.data[item].time,
              maxPlayers: response.data[item].maxPlayers,
              arena: response.data[item].arena,
              scoreHome: response.data[item].scoreHome,
              scoreAway: response.data[item].scoreAway,
              totalPrice: response.data[item].totalPrice,
              wontGo: wontGoList,
              dontKnow: dontKnowList,
              homeTeamRoster: newHomeRoster,
              awayTeamRoster: newAwayRoster,
              homeTeamGoals: newHomeGoals,
              awayTeamGoals: newAwayGoals,
              homeHasGoalie: homeHasGoalie,
              awayHasGoalie: awayHasGoalie,
              isOld: isOldDay(response.data[item].date),
              id: item,
            });
          }
          setLoading(false);
          setMatches(newMatches);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchVersion();
    if (token) {
      loadUsers();
    }
    loadMatches();
  }, [loadMatches, token, loadUsers, fetchVersion]);

  useEffect(() => {
    if (matchesLength && !selected && futureMatches.length) {
      setSelected(futureMatches[0].id);
    }
  }, [matchesLength, futureMatches, selected]);

  const hideOldMatchesHandler = () => {
    setHideOld(hideOld => !hideOld);
  };
  const selectMatchHandler = id => {
    setSelected(id);
  };

  const newMatchChangeHandler = event => {
    const newNewMatch = cloneDeep(newMatch);
    newNewMatch[event.target.name] = event.target.value;
    setNewMatch(newNewMatch);
  };

  const addNewMatchHandler = () => {
    const notValid = !newMatch.date;
    if (notValid) {
      setErrorMessage(t('Musíš vyplniť dátum'));
      return;
    }
    axios.post(`matches.json?auth=${token}`, newMatch).then(response => {
      loadMatches();
    });
    setNewMatch(initialMatchState);
  };

  const addPlayerHandler = (type, team, guestName, player, isGoalie) => {
    setErrorMessage('');
    const newMatches = cloneDeep(matches);
    const wantedMatch = newMatches.find(match => match.id === selected);
    const playersLimit = wantedMatch.maxPlayers;
    const nameNotValid = type === 'guest' && guestName.length < 2;
    if (nameNotValid) {
      setErrorMessage(t('Meno musí mať aspoň 2 znaky'));
      return;
    }
    const playersTotal =
      wantedMatch.homeTeamRoster.length + wantedMatch.awayTeamRoster.length;
    if (+playersTotal >= +playersLimit) {
      setErrorMessage(t('Bol naplnený limit hráčov na tento zápas'));
      return;
    }
    const comparisonKey = type === 'guest' ? 'name' : 'playerID';
    const comparisonValue =
      type === 'guest'
        ? guestName
        : type === 'bhlPlayer'
        ? player.playerID
        : props.auth.playerID;
    const isInHomeRoster = wantedMatch.homeTeamRoster.some(
      player =>
        player[comparisonKey] && player[comparisonKey] === comparisonValue
    );
    const isInAwayRoster = wantedMatch.awayTeamRoster.some(
      player =>
        player[comparisonKey] && player[comparisonKey] === comparisonValue
    );
    const isInRoster = isInHomeRoster || isInAwayRoster;
    if (isInRoster) {
      setErrorMessage(t('Tento hráč už je na súpiske'));
      return;
    }
    const rosterType = `${team}TeamRoster`;
    const newPlayer =
      type === 'guest'
        ? { playerID: '', name: guestName, isGoalie: isGoalie }
        : {
            playerID:
              type === 'bhlPlayer' ? player.playerID : props.auth.playerID,
            name: type === 'bhlPlayer' ? player.name : formatName(props.auth),
            isGoalie: isGoalie,
          };

    axios
      .post(`matches/${selected}/${rosterType}.json?auth=${token}`, newPlayer)
      .then(response => {
        loadMatches();
        setEditable('');
      })
      .catch(error => console.log(error));
  };

  const deletePlayerHandler = async (team, id) => {
    const confirmResult = await confirmModalAsync({
      props: props,
      title: t('Odstrániť hráča'),
      question: t('Naozaj chceš odstrániť tohto hráča zo súpisky?'),
    });

    if (confirmResult) {
      const newMatches = cloneDeep(matches);
      const wantedMatchIndex = newMatches.findIndex(
        match => match.id === selected
      );
      const rosterType = `${team}TeamRoster`;
      newMatches[wantedMatchIndex][rosterType] = [
        ...newMatches[wantedMatchIndex][rosterType].filter(
          player => player.id !== id
        ),
      ];
      setMatches(newMatches);
      axios
        .delete(`matches/${selected}/${rosterType}/${id}.json?auth=${token}`)
        .catch(error => console.log(error));
    }
  };

  const movePlayerHandler = (team, player) => {
    const newPlayer = { playerID: player.playerID, name: player.name };
    const newMatches = cloneDeep(matches);
    const wantedMatchIndex = newMatches.findIndex(
      match => match.id === selected
    );
    const rosterType = `${team}TeamRoster`;
    newMatches[wantedMatchIndex][rosterType] = [
      ...newMatches[wantedMatchIndex][rosterType].filter(
        plr => plr.id !== player.id
      ),
    ];
    const newTeam = team === 'home' ? 'away' : 'home';
    const newRosterType = `${newTeam}TeamRoster`;

    newMatches[wantedMatchIndex][newRosterType].push(newPlayer);
    setMatches(newMatches);
    setLoading(true);
    axios.delete(
      `matches/${selected}/${rosterType}/${player.id}.json?auth=${token}`
    );

    axios
      .post(
        `matches/${selected}/${newRosterType}.json?auth=${token}`,
        newPlayer
      )
      .then(res => loadMatches())
      .catch(error => console.log(error))
      .catch(error => console.log(error));
  };

  const beforeRemoveMatchHandler = async id => {
    const confirmResult = await confirmModalAsync({
      props: props,
      title: t('Odstrániť zápas'),
      question: t(
        'Vymažú sa aj súpisky zo zápasu a všetky súvisiace štatistiky. Natrvalo odstrániť tento zápas?'
      ),
    });

    if (confirmResult) {
      removeHandler(id);
    }
  };

  const removeHandler = id => {
    let newMatches = matches.filter(match => match.id !== id);
    setSelected('');
    setMatches(newMatches);
    axios.delete(`matches/${id}.json?auth=${token}`);
  };

  const editHandler = id => {
    setEditable(id);
  };

  const edit = (e, id, team, goalID) => {
    if ((!team || !goalID) && id !== editable) {
      return;
    }

    const newMatches = cloneDeep(matches);
    const wantedIndex = matches.findIndex(match => match.id === id);
    if (goalID) {
      const teamGoals = team === 'home' ? 'homeTeamGoals' : 'awayTeamGoals';
      const goalIndex = newMatches[wantedIndex][teamGoals].findIndex(
        goal => goal.id === goalID
      );

      newMatches[wantedIndex][teamGoals][goalIndex][e.target.name] =
        e.target.value;
    } else {
      newMatches[wantedIndex][e.target.name] = e.target.value;
    }

    setMatches(newMatches);
  };

  const secondaryRegHandler = (id, type) => {
    const data = {
      playerID: playerID,
      name: `${surname} ${name.slice(0, 1)}.`,
    };
    axios
      .post(`matches/${id}/${type}.json?auth=${token}`, data)
      .then(response => {
        loadMatches();
      })
      .catch(error => console.log(error));
  };
  const secondaryDeleteHandler = (matchID, playerID, type) => {
    axios
      .delete(`matches/${matchID}/${type}/${playerID}.json?auth=${token}`)
      .then(response => {
        loadMatches();
      })
      .catch(error => console.log(error));
  };

  const postEditedMatch = id => {
    const data = cloneDeep(matches).find(match => match.id === id);
    delete data.id;
    delete data.homeTeamRoster;
    delete data.awayTeamRoster;
    delete data.homeTeamGoals;
    delete data.awayTeamGoals;

    axios
      .patch(`matches/${id}.json?auth=${token}`, data)
      .then(response => {
        loadMatches();
        setEditable('');
      })
      .catch(error => console.log(error));
  };

  const postGoalHandler = async (team, goal) => {
    const newSelectedMatch = matches.find(match => match.id === selected);
    const scoreForCheck =
      team === 'home' ? newSelectedMatch.scoreHome : newSelectedMatch.scoreAway;
    const listedGoalsForCheck =
      team === 'home'
        ? newSelectedMatch.homeTeamGoals
        : newSelectedMatch.awayTeamGoals;
    const goalsNotValid = listedGoalsForCheck.length >= scoreForCheck;
    if (goalsNotValid) {
      await confirmModalAsync({
        props: props,
        alertOnly: true,
        title: t('Chyba'),
        question: t('Nemôžeš zapísať viac gólov ako je skóre daného tímu'),
      });
      return;
    }
    const key = team === 'home' ? 'homeTeamGoals' : 'awayTeamGoals';

    axios
      .post(`matches/${selected}/${key}.json?auth=${token}`, goal)
      .then(response => {
        loadMatches();
      })
      .catch(error => console.log(error));
  };

  const postEditedGoal = async (team, goal) => {
    const key = team === 'home' ? 'homeTeamGoals' : 'awayTeamGoals';

    const data = {
      goal: { name: goal.goal.name, playerID: goal.goal.playerID },
      assist: { name: goal.assist.name, playerID: goal.assist.playerID },
    };

    axios
      .patch(`matches/${selected}/${key}/${goal.id}.json?auth=${token}`, data)
      .then(response => {
        loadMatches();
      })
      .catch(error => console.log(error));
  };

  const deleteGoalHandler = async (team, id) => {
    const confirmResult = await confirmModalAsync({
      props: props,
      title: t('Odstrániť gól'),
      question: t('Naozaj chceš odstrániť tento gól?'),
    });

    if (confirmResult) {
      const key = team === 'home' ? 'homeTeamGoals' : 'awayTeamGoals';
      const newMatches = cloneDeep(matches);
      const wantedIndex = newMatches.findIndex(match => match.id === selected);
      newMatches[wantedIndex][key] = [
        ...newMatches[wantedIndex][key].filter(goal => goal.id !== id),
      ];
      setMatches(newMatches);
      axios
        .delete(`matches/${selected}/${key}/${id}.json?auth=${token}`)
        .catch(error => console.log(error));
    }
  };

  const matchesList = genericSort(futureMatches, false, 'date', 'date').map(
    match => (
      <Match
        key={match.id}
        data={match}
        selectHandler={() => selectMatchHandler(match.id)}
        isSelected={selected === match.id}
        deleteHandler={() => beforeRemoveMatchHandler(match.id)}
        editHandler={select => editHandler(select ? match.id : '')}
        saveHandler={() => postEditedMatch(match.id)}
        changeHandler={e => edit(e, match.id)}
        isEditable={editable === match.id}
        canEdit={canEdit}
      />
    )
  );

  return (
    <Box>
      <Box>
        {!hideOld &&
          oldMatches.length &&
          genericSort(oldMatches, false, 'date', 'date').map(oldMatch => (
            <OldMatch
              key={oldMatch.id}
              data={oldMatch}
              selectHandler={() => selectMatchHandler(oldMatch.id)}
              saveHandler={() => postEditedMatch(oldMatch.id)}
              changeHandler={e => edit(e, oldMatch.id)}
              isSelected={selected === oldMatch.id}
              editHandler={select => editHandler(select ? oldMatch.id : '')}
              deleteHandler={() => beforeRemoveMatchHandler(oldMatch.id)}
              isEditable={editable === oldMatch.id}
              showRoster={showRoster}
              setShowRoster={setShowRoster}
              canEdit={canEdit}
              isPlayer={isPlayer}
              playerID={playerID}
              users={users}
              addPlayerHandler={addPlayerHandler}
              deletePlayerHandler={deletePlayerHandler}
              moveHandler={movePlayerHandler}
              loading={loading}
              errorMessage={errorMessage}
              postHandler={postGoalHandler}
              postEditedGoal={postEditedGoal}
              deleteGoalHandler={deleteGoalHandler}
              secondaryRegHandler={type =>
                secondaryRegHandler(oldMatch.id, type)
              }
              secondaryDeleteHandler={(playerID, type) =>
                secondaryDeleteHandler(oldMatch.id, playerID, type)
              }
            />
          ))}
      </Box>
      <Row>
        <Button
          text={
            hideOld ? t('Zobraziť minulé zápasy') : t('Skryť minulé zápasy')
          }
          type={hideOld ? 'blue' : 'orange'}
          icon={'history'}
          onClick={() => hideOldMatchesHandler()}
          my='5px'
        />
      </Row>
      <MatchesRowStyled>
        {loading ? <Loading /> : matchesList}

        {canEdit && (
          <Match
            changeHandler={newMatchChangeHandler}
            data={newMatch}
            addNewMatchHandler={addNewMatchHandler}
            isNewMatch
          />
        )}
      </MatchesRowStyled>
      {selectedMatch ? (
        <Box>
          <SwitchRow showRoster={showRoster} setShowRoster={setShowRoster} />
          {showRoster ? (
            <MatchDetail
              data={selectedMatch}
              users={users}
              addPlrHandler={addPlayerHandler}
              deleteHandler={deletePlayerHandler}
              moveHandler={movePlayerHandler}
              loading={loading}
              canEdit={canEdit}
              isPlayer={isPlayer}
              playerID={playerID}
              errorMessage={errorMessage}
              secondaryRegHandler={type =>
                secondaryRegHandler(selectedMatch.id, type)
              }
              secondaryDeleteHandler={(playerID, type) =>
                secondaryDeleteHandler(selectedMatch.id, playerID, type)
              }
            />
          ) : (
            <MatchDetailGoals
              data={selectedMatch}
              postHandler={postGoalHandler}
              postEditedGoal={postEditedGoal}
              deleteHandler={deleteGoalHandler}
              canEdit={canEdit || isPlayer}
              playerID={playerID}
            />
          )}
        </Box>
      ) : (
        <MessageBox warning>
          {t('Nie je vybraný žiadny aktuálny zápas')}
        </MessageBox>
      )}
      {isPlayer && <Chat />}
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: (component, componentProps, title) =>
      dispatch(actionCreators.openModal(component, componentProps, title)),
    closeModal: () => dispatch(actionCreators.closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Matches);

const MatchesRowStyled = styled(Row)`
  width: 100%;
  flex-wrap: wrap;
  @media ${mediaQueries.s} {
    flex-direction: column;
  }
`;
