export const API_KEY = 'AIzaSyBHVesKvmgyPwMmn0vn8Zak7G5UlH6gLz0';
export const baseURL = 'https://bhliga-b3a51-default-rtdb.firebaseio.com/';

export const AUTH_URL = 'https://identitytoolkit.googleapis.com/v1/accounts:';

export const SLUG_PASSWORD_RESET = 'sendOobCode?key=';
export const SLUG_UPDATE = 'update?key=';
export const SLUG_SIGN_IN = 'signInWithPassword?key=';
export const SLUG_SIGN_UP = 'signUp?key=';

export const DATABASE_KEYS = {
  MATCHES: 'matches',
  PLAYERS: 'players',
  USERS: 'users',
  ARENASL: 'arenas',
};
