import React from 'react';
import styled from 'styled-components';

import { Box } from 'components/BasicComponents';

import white from 'assets/images/vbiely.png';
import red from 'assets/images/vcerveny.png';
import whiteBack from 'assets/images/jersey_white_back.png';
import redBack from 'assets/images/jersey_red_back.png';

const Jersey = ({
  type,
  size = '2rem',
  fontSize = '1.3rem',
  nameSize = '0.4rem',
  numTop = '0.65rem',
  nameTop = '0.5rem',
  margin,
  back,
  num = '00',
  className,
  name,
}) => (
  <JerseyStyled
    bg={type === 'red' ? (back ? redBack : red) : back ? whiteBack : white}
    size={size}
    margin={margin}
    className={className}
  >
    {back && name && (
      <NameStyled
        color={type === 'red' ? 'white' : 'black'}
        nameTop={nameTop}
        nameSize={nameSize}
      >
        {name.length >= 9 ? name.slice(0, 8) : name}
      </NameStyled>
    )}
    {back && (
      <NumStyled
        fontSize={fontSize}
        color={type === 'red' ? 'white' : 'black'}
        numTop={numTop}
      >
        {num}
      </NumStyled>
    )}
  </JerseyStyled>
);

export default Jersey;

const JerseyStyled = styled(Box)`
  background: url(${props => props.bg}) no-repeat center center;
  background-size: contain;
  width: ${props => props.size};
  height: ${props => props.size};
  margin: ${props => props.margin || '0 0'};
  font-weight: 700;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const NumStyled = styled(Box)`
  top: ${props => props.numTop};
  position: absolute;
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
`;
const NameStyled = styled(Box)`
  position: absolute;
  font-weight: 700;
  font-size: ${props => props.nameSize};
  color: ${props => props.color};
  align-items: center;
  justify-content: center;
  top: ${props => props.nameTop};
  right: 0;
  left: 0;
`;
