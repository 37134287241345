import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import axios from 'common/axios';
import { AUTHORIZATION } from 'common/constants';
import * as actionCreators from 'store/actions';
import { Box } from 'components/BasicComponents';
import Loading from 'components/UI/Loading';
import { confirmModalAsync } from 'components/UI/ConfirmModal';

import User from './User';

const Users = props => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [users, setUsers] = useState([]);

  const token = props.auth.token;
  // const playerID = props.auth.playerID;
  // const isPlayer =
  //   token &&
  //   (props.auth.authorization?.includes(AUTHORIZATION.PLAYER) ||
  //     props.auth.authorization?.includes(AUTHORIZATION.ADMIN) ||
  //     props.auth.authorization?.includes(AUTHORIZATION.OWNER));
  const canEdit =
    token && props.auth.authorization?.includes(AUTHORIZATION.OWNER);

  const usersLoad = useCallback(() => {
    setLoading(true);
    const queryParams = `?auth=${token}`;
    axios
      .get(`users.json${queryParams}`)
      .then(response => {
        const newUsers = [];
        if (!response?.data) {
          setLoading(false);
          return;
        } else {
          Object.keys(response.data).forEach(id => {
            newUsers.push({
              email: response.data[id].email,
              phone: response.data[id].phone,
              name: response.data[id].name,
              surname: response.data[id].surname,
              jerseyNumber: response.data[id].jerseyNumber,
              defaultTeam: response.data[id].defaultTeam,
              shoot: response.data[id].shoot,
              birthDate: response.data[id].birthDate,
              authorization: response.data[id]?.authorization,
              playerID: response.data[id].playerID,
              userID: response.data[id].userID,
              town: response.data[id].town,
              id: id,
            });
          });
          setUsers(newUsers);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    usersLoad();
  }, [usersLoad]);

  const beforeRemoveHandler = async user => {
    const confirmResult = await confirmModalAsync({
      props: props,
      title: t('Odstrániť používateľa'),
      question: `${t('Naozaj chceš odstrániť používateľa')} ${user.name} ${
        user.surname
      }?`,
    });

    if (confirmResult) {
      removeHandler(user);
    }
  };

  const removeHandler = user => {
    axios
      .delete(`users/${user.id}.json?auth=${token}`)
      .then(response => {
        usersLoad();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const userEdit = (e, id) => {
    const wantedIndex = users.findIndex(row => row.id === id);
    const newUsers = cloneDeep(users);
    newUsers[wantedIndex][e.target.name] = e.target.value;
    setUsers(newUsers);
  };

  const postEditedUser = id => {
    const playerID = users.find(user => user.id === id).playerID;
    if (
      playerID &&
      users.filter(user => user.playerID === playerID).length > 1
    ) {
      setErrorMessage(`ID "${playerID}" už je obsadené`);
      return;
    }
    setErrorMessage('');
    const data = users.find(user => user.id === id);
    delete data.id;
    axios
      .patch(`users/${id}.json?auth=${token}`, data)
      .then(response => {
        setEditable('');
        usersLoad();
      })
      .catch(error => console.log(error));
  };

  if (loading) {
    return <Loading />;
  }

  const usersList = users.map((user, index) => (
    <User
      key={user.id}
      data={user}
      index={index}
      isEditable={editable === user.id}
      errorMessage={errorMessage}
      blueButton={
        editable === user.id
          ? () => postEditedUser(user.id)
          : () => setEditable(user.id)
      }
      redButton={
        editable === user.id
          ? () => setEditable('')
          : () => beforeRemoveHandler(user)
      }
      edit={e => userEdit(e, user.id)}
      canEdit={canEdit}
    />
  ));

  return (
    <Box>
      <h1>{t('Registrovaní hráči BHL')}</h1>
      <p>
        {t('Počet registrovaných')}: <strong>{usersList.length}</strong>
      </p>
      {!!usersList.length && <User isLabel />}
      {usersList}
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: (component, componentProps, title) =>
      dispatch(actionCreators.openModal(component, componentProps, title)),
    closeModal: () => dispatch(actionCreators.closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
