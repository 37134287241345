import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { mediaQueries, sizes } from 'common/theme';
import { theme } from 'common/theme';
import { Row } from './BasicComponents';
import Logo from './UI/Logo';
import Menu from './Menu';
import UserInfo from './UserInfo';
import puckBack from 'assets/images/puck.png';

const Header = ({ install }) => {
  const headerVisibleThreshold = theme.headerVisibleThreshold;
  const headerCompactThreshold = theme.headerCompactThreshold;
  const permanentCompactHeaderWidth = theme.permanentCompactHeaderWidth;

  const [isVisible, setIsVisible] = useState(true);
  const [isCompact, setIsCompact] = useState(false);

  let lastScrollTop = 0;
  const applyCompactHeader = window.innerWidth > permanentCompactHeaderWidth;

  const handleInstall = () => {
    if (install) {
      install.prompt();
      install.userChoice.then(result => {
        console.log(result.outcome);
      });
    }
  };

  const updateHeaderIsCompact = () => {
    const currentPosition = window.pageYOffset;

    if (applyCompactHeader) {
      if (currentPosition > headerCompactThreshold && !isCompact) {
        setIsCompact(true);
      } else if (currentPosition < headerCompactThreshold && isCompact) {
        setIsCompact(false);
      }
    }
  };

  window.onscroll = () => updateHeaderIsCompact();

  const updateHeaderVisibility = useCallback(() => {
    const currentPosition = window.pageYOffset;
    if (
      currentPosition > lastScrollTop &&
      currentPosition > headerVisibleThreshold
    ) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    lastScrollTop = currentPosition <= 0 ? 0 : currentPosition;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', updateHeaderVisibility, {
      passive: false,
    });
    return () => {
      window.removeEventListener('scroll', updateHeaderVisibility, {
        passive: false,
      });
    };
  }, [updateHeaderVisibility]);

  return (
    <HeaderStyled isCompact={isCompact} isVisible={isVisible}>
      <HeaderInnerStyled>
        <Logo mini={isCompact} handleInstall={handleInstall} />
        <Menu />
        <UserInfo />
      </HeaderInnerStyled>
    </HeaderStyled>
  );
};

export default Header;

const HeaderStyled = styled.header`
  background: url(${puckBack}) #202020;
  background-size: 0.8rem;
  width: 100%;
  height: ${props =>
    props.isCompact
      ? props.theme.headerHeight.compact
      : props.theme.headerHeight.desktop};
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  position: fixed;
  z-index: ${props => props.theme.zIndex.header};
  transition: all 0.3s;
  transform: translateY(${props => (props.isVisible ? '0' : '-100')}%);

  @media ${mediaQueries.l} {
    height: ${props => props.theme.headerHeight.compact};
  }

  @media ${mediaQueries.m} {
    bottom: 0;
    height: ${props => props.theme.headerHeight.mobile};
    padding: 0 0.5rem;
    transform: translateY(0);
  }
`;
const HeaderInnerStyled = styled(Row)`
  width: 100%;
  max-width: ${sizes.xl};
  height: 100%;
  justify-content: space-between;
`;
