import React from 'react';
import { connect } from 'react-redux';

import * as actionCreators from 'store/actions/index';

const ModalF = props => {
  if (props.selectedComponent) {
    return React.createElement(props.selectedComponent, {
      ...props.selectedComponentProps,
      title: props.title,
      closeModal: props.closeModal,
      // setFlash: props.setFlash,
      // startProcessing: props.startProcessing,
      // stopProcessing: props.stopProcessing,
      // processing: props.processing,
    });
  }

  return <></>;
};

const mapStateToProps = state => {
  return {
    title: state.modals.title,
    selectedComponent: state.modals.selectedComponent,
    selectedComponentProps: state.modals.selectedComponentProps,
    processing: state.modals.processing,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actionCreators.closeModal()),
  };
};

export const Modal = connect(mapStateToProps, mapDispatchToProps)(ModalF);
