import React from 'react';
import styled from 'styled-components';

const ProgressCircle = ({
  value = 0,
  size = '2rem',
  textSize = '2.8rem',
  margin = '2rem',
}) => {
  const formattedValue = value >= 100 ? 100 : value <= 0 ? 0 : value;
  const isCompleted = formattedValue === 100;
  const isZero = formattedValue === 0;

  return (
    <ProgressCircleStyled
      isCompleted={isCompleted}
      size={size}
      textSize={textSize}
      margin={margin}
      isZero={isZero}
    >
      <span>{formattedValue}%</span>
      <LeftHalfClipperStyled className={formattedValue > 50 ? 'over50' : ''}>
        <First50BarStyled
          isCompleted={isCompleted}
          className={formattedValue > 50 ? 'over50' : ''}
        />
        <ValueBarStyled
          value={Math.floor(formattedValue * 3.6)}
          isCompleted={isCompleted}
        />
      </LeftHalfClipperStyled>
    </ProgressCircleStyled>
  );
};

export default ProgressCircle;

const ProgressCircleStyled = styled.div`
  font-size: ${props => props.size};
  margin: ${props => props.margin};
  position: relative;
  padding: 0;
  width: 5em;
  min-width: 5em;
  height: 5em;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  line-height: 5em;

  &:after {
    border: none;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 4.3em;
    height: 4.3em;
    background: #000;
    content: ' ';
  }

  & span {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: ${props => props.textSize};
    font-weight: bold;
    z-index: 2;
    color: ${props =>
      props.isCompleted
        ? props.theme.colors.progressCompleted
        : props.isZero
        ? props.theme.colors.tertiary
        : props.theme.colors.progress};
  }
`;

const ValueBarStyled = styled.div`
  position: absolute;
  clip: rect(0, 2.5em, 5em, 0);
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 0.45em solid
    ${props =>
      props.isCompleted
        ? props.theme.colors.progressCompleted
        : props.theme.colors.progress};
  box-sizing: border-box;
  display: ${props => (props.value === 0 ? 'none' : '')};
  transform: ${props => `rotate(${props.value}deg)`};
`;
const First50BarStyled = styled.div`
  display: none;
  &.over50 {
    display: block;
    position: absolute;
    clip: rect(0, 5em, 5em, 2.5em);
    background: ${props =>
      props.isCompleted
        ? props.theme.colors.progressCompleted
        : props.theme.colors.progress};
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
`;
const LeftHalfClipperStyled = styled.div`
  border-radius: 50%;
  width: 5em;
  height: 5em;
  position: absolute;
  clip: rect(0, 5em, 5em, 2.5em);
  &.over50 {
    clip: rect(auto, auto, auto, auto);
  }
`;
