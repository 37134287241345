import React from 'react';
import styled from 'styled-components';

import { Icons } from 'common/Icons';

export const Box = ({ className, children, ...props }) => (
  <BoxStyled className={className} {...props}>
    {children}
  </BoxStyled>
);

export const Row = ({ className, children, ...props }) => (
  <RowStyled className={className} {...props}>
    {children}
  </RowStyled>
);

export const MessageBox = ({
  className,
  children,
  warning = false,
  success = false,
  ...props
}) => (
  <MessageBoxStyled
    className={className}
    warning={warning}
    success={success}
    {...props}
  >
    <i className={Icons[warning ? 'info' : success ? 'success' : 'warning']} />
    {children}
  </MessageBoxStyled>
);

const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowStyled = styled.div`
  display: flex;
`;
const MessageBoxStyled = styled(Row)`
  font-size: 1.4rem;
  font-weight: 600;
  max-width: 80rem;
  background: ${props =>
    props.warning
      ? props.theme.colors.warning
      : props.success
      ? props.theme.colors.success
      : props.theme.colors.errorDark};
  color: ${props =>
    props.warning
      ? props.theme.colors.warningText
      : props.success
      ? props.theme.colors.successText
      : props.theme.colors.errorText};
  padding: 1rem;
  border-radius: ${props => props.theme.radius.s};
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0.7rem;
  & i {
    margin-right: 0.7rem;
  }
  & i:not(:first-of-type) {
    margin: 0 0.7rem;
  }
`;
