import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { arenas } from 'common/constants';
import { mediaQueries } from 'common/theme';
import { formatISODate } from 'common/utils';
import Jersey from 'components/Jersey';
import { Icons } from 'common/Icons';
import Button from 'components/UI/Button';
import { Box, Row } from 'components/BasicComponents';
import Input from 'components/UI/Input';

export const Match = ({
  data,
  isActual,
  isEditable,
  isSelected,
  selectHandler,
  changeHandler,
  deleteHandler,
  editHandler,
  saveHandler,
  addNewMatchHandler,
  isNewMatch,
  canEdit,
}) => {
  const { t } = useTranslation();

  const {
    date,
    time,
    arena,
    scoreHome,
    scoreAway,
    totalPrice,
    maxPlayers,
    homeTeamRoster,
    awayTeamRoster,
    homeHasGoalie,
    awayHasGoalie,
  } = data;

  const playersTotal = homeTeamRoster.length + awayTeamRoster.length;

  if (isNewMatch) {
    return (
      <MatchStyled newMatch>
        <Box>
          <MatchRowStyled>
            <MatchColumnStyled>
              <Input
                name='date'
                type='date'
                value={date}
                onChange={e => changeHandler(e)}
                height='2.5rem'
                width='13rem'
              />
            </MatchColumnStyled>
            <MatchColumnStyled>
              <Input
                name='time'
                type='time'
                value={time}
                onChange={e => changeHandler(e)}
                height='2.5rem'
                width='9rem'
              />
            </MatchColumnStyled>
          </MatchRowStyled>
          <MatchRowStyled>
            <Input
              type='select'
              name='arena'
              value={arena}
              onChange={e => changeHandler(e)}
              height='3rem'
              mini
            >
              {arenas.map(arena => (
                <option>{arena.name}</option>
              ))}
            </Input>
          </MatchRowStyled>
          <MatchRowStyled>
            <Input
              name='totalPrice'
              placeholder={t('Cena')}
              maxLength='4'
              type='text'
              value={totalPrice}
              onChange={e => changeHandler(e)}
              height='2.5rem'
              width='5.5rem'
              label='€ (odhad)'
            />
          </MatchRowStyled>
        </Box>
        <Button
          type='greenFull'
          icon='plus'
          onClick={addNewMatchHandler}
          text={t('Pridať')}
        />
      </MatchStyled>
    );
  }

  return (
    <Box>
      <MatchStyled
        isActual={isActual}
        isEditable={isEditable}
        onClick={selectHandler}
        isSelected={isSelected}
      >
        {canEdit && (
          <>
            <ButtonRowStyled>
              <Button
                type='red'
                icon={isEditable ? 'cancel' : 'delete'}
                onClick={() =>
                  isEditable ? editHandler(false) : deleteHandler()
                }
              />
            </ButtonRowStyled>
            <ButtonRowStyled className='edit'>
              <Button
                type={isEditable ? 'green' : 'blue'}
                icon={isEditable ? 'save' : 'edit'}
                onClick={() => (isEditable ? saveHandler() : editHandler(true))}
              />
            </ButtonRowStyled>
          </>
        )}
        <MatchRowStyled>
          <MatchColumnStyled>
            {!isEditable && <i className={Icons.calendar} />}
            {isEditable ? (
              <Input
                name='date'
                type='date'
                value={date}
                onChange={e => changeHandler(e)}
                height='2.5rem'
                width='13rem'
              />
            ) : (
              formatISODate(date)
            )}
          </MatchColumnStyled>
          <MatchColumnStyled>
            {!isEditable && <i className={Icons.clock} />}
            {isEditable ? (
              <Input
                name='time'
                type='time'
                value={time}
                onChange={e => changeHandler(e)}
                height='2.5rem'
                width='9rem'
              />
            ) : (
              time
            )}
          </MatchColumnStyled>
        </MatchRowStyled>
        <MatchRowStyled>
          <RowStyled>
            <i className={Icons.mapmarker} />
            {isEditable ? (
              <Input
                type='select'
                name='arena'
                value={arena}
                onChange={e => changeHandler(e)}
                height='3rem'
              >
                {arenas.map(arena => (
                  <option>{arena.name}</option>
                ))}
              </Input>
            ) : (
              arena
            )}
          </RowStyled>
          <RowStyled>
            {isEditable ? (
              <>
                <Input
                  name='maxPlayers'
                  maxLength='2'
                  type='text'
                  value={maxPlayers}
                  onChange={e => changeHandler(e)}
                  height='2.5rem'
                  width='3rem'
                  mini
                />
                {t('max')}
              </>
            ) : (
              `${playersTotal} / ${maxPlayers}`
            )}
          </RowStyled>
        </MatchRowStyled>
        {isEditable && (
          <MatchRowStyled>
            <Input
              name='totalPrice'
              placeholder={t('Cena')}
              maxLength='4'
              type='text'
              value={totalPrice}
              onChange={e => changeHandler(e)}
              height='2.5rem'
              width='5.5rem'
              label='€ (odhad)'
            />
          </MatchRowStyled>
        )}
        <MatchRowStyled>
          <GoalieBoxStyled isGreen={homeHasGoalie}>G</GoalieBoxStyled>
          <Jersey size='2rem' />
          <ScoreRowStyled>
            {isEditable ? (
              <>
                <Input
                  name='scoreHome'
                  maxLength='2'
                  type='text'
                  value={scoreHome}
                  onChange={e => changeHandler(e)}
                  height='2.5rem'
                  width='4rem'
                />
                :
                <Input
                  name='scoreAway'
                  maxLength='2'
                  type='text'
                  value={scoreAway}
                  onChange={e => changeHandler(e)}
                  height='2.5rem'
                  width='4rem'
                />
              </>
            ) : (
              `${scoreHome} : ${scoreAway}`
            )}
          </ScoreRowStyled>
          <Jersey type='red' size='2rem' />
          <GoalieBoxStyled isGreen={awayHasGoalie}>G</GoalieBoxStyled>
        </MatchRowStyled>
      </MatchStyled>
    </Box>
  );
};

const MatchStyled = styled(Box)`
  min-width: 25rem;
  background: ${props =>
    props.isEditable || props.newMatch ? 'transparent' : '#FFFFFF20'};
  align-items: center;
  border-radius: 0.7rem;
  border: 1px solid
    ${props => (props.isSelected ? props.theme.colors.redLight : 'transparent')};
  position: relative;
  padding: 0.7rem;
  margin: 0.5rem;
  cursor: pointer;
  flex-direction: ${props => (props.newMatch ? 'row' : '')};
  flex-wrap: ${props => (props.newMatch ? 'wrap' : '')};
  @media ${mediaQueries.s} {
    margin: 0.5rem 0;
    width: 100% !important;
    flex-direction: ${props => (props.newMatch ? 'column' : '')};
    flex-wrap: ${props => (props.newMatch ? 'nowrap' : '')};
  }
  &::after {
    background: ${props => (props.isSelected ? '#200000' : 'transparent')};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0 0 50rem 3rem
      ${props => (props.isSelected ? props.theme.colors.red : 'transparent')};
    @media ${mediaQueries.s} {
      box-shadow: 0 0 50rem 3rem
        ${props =>
          props.isSelected ? props.theme.colors.redLight : 'transparent'};
    }
  }
  &:hover {
    background: ${props =>
      props.isEditable || props.newMatch ? '' : '#FFFFFF28'};
  }
`;

const MatchRowStyled = styled(Row)`
  width: 100%;
  min-height: 4rem;
  justify-content: center;
  align-items: center;
  & i {
    margin-right: 0.7rem;
    color: ${props => props.theme.colors.tertiary};
  }
  @media ${mediaQueries.s} {
    min-height: 3rem;
  }
`;
const RowStyled = styled(Row)`
  justify-content: center;
  align-items: center;
  &:nth-of-type(2) {
    padding-left: 2rem;
  }
`;
const GoalieBoxStyled = styled(Box)`
  background: ${props => (props.isGreen ? '#70f73b' : '#FF7870')};
  border-radius: 3rem;
  width: 2rem;
  height: 2rem;
  color: #000;
  font-size: 1.6rem;
  padding: 0 1rem;
  margin: 0 1rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const MatchColumnStyled = styled(Row)`
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const ScoreRowStyled = styled(Row)`
  min-width: 4.5rem;
  font-weight: 700;
  font-size: 2rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
`;
const ButtonRowStyled = styled(Row)`
  min-width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  justify-content: center;
  align-items: center;
  &.edit {
    bottom: 0.5rem;
    top: auto;
  }
`;
