import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';

import axios from 'common/axios';
import { API_KEY, AUTH_URL, SLUG_UPDATE } from 'common/axiosSettings.js';
import { isValidEmail, formatISODate } from 'common/utils';
import { Box, Row, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';

import qr from 'assets/images/QR.png';

const Settings = props => {
  const { t } = useTranslation();
  const [emailChange, setEmailChange] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [editable, setEditable] = useState('');
  const [message, setMessage] = useState('');
  const [user, setUser] = useState({
    name: '',
    surname: '',
    phone: '',
    jerseyNumber: '',
    defaultTeam: '',
    shoot: '',
    birthDate: '',
    town: '',
    dataUserID: '',
  });

  const token = props.auth.token;
  const userID = props.auth.userID;

  const loadUserSettings = useCallback(() => {
    setLoading(true);
    const queryParams = `?auth=${token}&orderBy="userID"&equalTo="${userID}"`;
    axios
      .get(`users.json${queryParams}`)
      .then(response => {
        const id = Object.keys(response.data)[0];
        setUser(user => ({
          ...user,
          name: response.data[id].name,
          surname: response.data[id].surname,
          jerseyNumber: response.data[id].jerseyNumber,
          defaultTeam: response.data[id].defaultTeam,
          shoot: response.data[id].shoot,
          birthDate: response.data[id].birthDate,
          town: response.data[id].town,
          phone: response.data[id].phone,
          dataUserID: [id],
        }));
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [token, userID]);

  useEffect(() => {
    loadUserSettings();
  }, [loadUserSettings]);

  const changeInputHandler = e => {
    setUser(user => ({ ...user, [e.target.name]: e.target.value }));
  };

  const postEditedUser = () => {
    if (user.name.trim() === '' || user.surname.trim() === '') {
      setMessage(t('Musíš vyplniť meno aj priezvisko'));
      return;
    }
    setLoading(true);
    const data = cloneDeep(user);
    delete data.dataUserID;
    axios
      .patch(`users/${user.dataUserID}.json?auth=${token}`, data)
      .then(response => {
        loadUserSettings();
        setEditable(false);
      })
      .catch(error => console.log(error));
  };

  const errorMsgs = [
    'INVALID_ID_TOKEN',
    'TOKEN_EXPIRED',
    'CREDENTIAL_TOO_OLD_LOGIN_AGAIN',
  ];

  const authUpdate = type => {
    if (type === 'password' && password.trim().length < 6) {
      setErrorMessage(t('Nové heslo musí mať aspoň 6 znakov'));
      return false;
    } else if (type === 'email') {
      if (!isValidEmail(email)) {
        setErrorMessage(t('Zadaný e-mail nie je správny'));
        return false;
      }
    }
    setErrorMessage('');
    const data = {
      idToken: token,
      returnSecureToken: false,
    };
    if (type === 'password') {
      data.password = password;
    } else {
      data.email = email;
    }

    const url = `${AUTH_URL}${SLUG_UPDATE}${API_KEY}`;

    axios
      .post(url, data)
      .then(response => {
        setPassword('');
        setEmailChange(false);
        setPasswordChange(false);
        alert(
          type === 'password'
            ? t('Tvoje heslo bolo zmenené')
            : t('Tvoj e-mail bol zmenený')
        );
      })
      .catch(error => {
        let errorMessage = error?.response?.data?.error?.message;
        if (errorMsgs.includes(error?.response?.data?.error?.message)) {
          errorMessage = t('Musíš sa znovu prihlásiť');
        } else if (error?.response?.data?.error?.message === 'WEAK_PASSWORD') {
          errorMessage = t('Slabé heslo');
        } else if (error?.response?.data?.error?.message === 'EMAIL_EXISTS') {
          errorMessage = t('Účet s týmto emailom už existuje');
        }
        setErrorMessage(errorMessage);
      });
  };

  return (
    <BoxStyled>
      <h2>{t('Platobné údaje')}</h2>
      <HighlightStyled>Lehuta Timotej</HighlightStyled>
      <HighlightStyled>SK3811110000001196728005</HighlightStyled>
      <h2>{t('QR Kód (treba vždy upraviť sumu)')}</h2>
      <a href='https://bhliga.eu/QR.png'>
        <IMGStyled src={qr} alt='SK3811110000001196728005' />
      </a>
      <h2>{t('Nastavenia hráča')}</h2>

      <UserRowStyled>
        <UserLabelStyled>{t('Meno')}:</UserLabelStyled>
        {editable ? (
          <>
            <Input
              name='name'
              placeholder={t('Meno')}
              onChange={e => changeInputHandler(e)}
              maxLength='15'
              type='text'
              value={user.name}
            />
            <Input
              name='surname'
              placeholder={t('Priezvisko')}
              onChange={e => changeInputHandler(e)}
              maxLength='20'
              type='text'
              value={user.surname}
            />
          </>
        ) : (
          <ValueStyled>{`${user.name} ${user.surname}`}</ValueStyled>
        )}
      </UserRowStyled>
      <UserRowStyled>
        <UserLabelStyled>{t('Číslo dresu')}:</UserLabelStyled>
        {editable ? (
          <Input
            name='jerseyNumber'
            placeholder={t('Dres')}
            onChange={e => changeInputHandler(e)}
            maxLength='2'
            type='text'
            value={user.jerseyNumber}
            width='5rem'
          />
        ) : (
          user.jerseyNumber
        )}
      </UserRowStyled>
      <UserRowStyled>
        <UserLabelStyled>{t('Preferovaný tím')}:</UserLabelStyled>
        {editable ? (
          <Input
            type='select'
            onChange={e => changeInputHandler(e)}
            name='defaultTeam'
            value={user.defaultTeam}
          >
            <option value=''>{t('Tím')}</option>
            <option value='white'>{t('Biely')}</option>
            <option value='red'>{t('Červený')}</option>
          </Input>
        ) : user.defaultTeam === 'red' ? (
          'Červený'
        ) : (
          'Biely'
        )}
      </UserRowStyled>
      <UserRowStyled>
        <UserLabelStyled>{t('Streľba')}:</UserLabelStyled>
        {editable ? (
          <Input
            type='select'
            onChange={e => changeInputHandler(e)}
            name='shoot'
            value={user?.shoot}
          >
            <option value=''>{t('Streľba')}</option>
            <option value='left'>{t('Ľavou')}</option>
            <option value='right'>{t('Pravou')}</option>
          </Input>
        ) : user.shoot === 'left' ? (
          t('Ľavou')
        ) : (
          t('Pravou')
        )}
      </UserRowStyled>
      <UserRowStyled>
        <UserLabelStyled>{t('Dátum narodenia')}:</UserLabelStyled>
        {editable ? (
          <Input
            name='birthDate'
            placeholder={t('Dátum narodenia')}
            onChange={e => changeInputHandler(e)}
            type='date'
            value={user.birthDate}
            width='12rem'
          />
        ) : (
          formatISODate(user.birthDate)
        )}
      </UserRowStyled>
      <UserRowStyled>
        <UserLabelStyled>{t('Bydlisko')}:</UserLabelStyled>
        {editable ? (
          <Input
            name='town'
            placeholder={t('Bydlisko')}
            onChange={e => changeInputHandler(e)}
            maxLength='22'
            type='text'
            value={user.town}
            width='12rem'
            height='3rem'
          />
        ) : (
          user.town
        )}
      </UserRowStyled>
      <UserRowStyled>
        <UserLabelStyled>{t('Tel. číslo')}:</UserLabelStyled>
        {editable ? (
          <Input
            key='phone'
            type='text'
            onChange={e => changeInputHandler(e)}
            name='phone'
            value={user?.phone}
            placeholder={t('0909 000 000')}
            maxLength='12'
            height='3rem'
            width='12rem'
          />
        ) : (
          user?.phone || '?'
        )}
      </UserRowStyled>
      <UserRowStyled>
        <Button
          text={editable ? t('Uložiť') : t('Upraviť')}
          type='blueFull'
          onClick={editable ? () => postEditedUser() : () => setEditable(true)}
          icon={editable ? 'save' : 'edit'}
        />
        {!!editable && (
          <Button
            text={t('Zrušiť')}
            type='redFull'
            onClick={() => setEditable(false)}
            icon='cancel'
          />
        )}
      </UserRowStyled>

      <h2>{t('Nastavenie prihlásenia')}</h2>

      {loading ? (
        <Loading />
      ) : (
        <Box>
          {!!message && <MessageBox>{message}</MessageBox>}

          <UserRowStyled>{t('E-mail')}:</UserRowStyled>

          {errorMessage && (
            <UserRowStyled>
              <MessageBox>{errorMessage}</MessageBox>
            </UserRowStyled>
          )}

          <UserRowStyled>
            {emailChange ? (
              <Input
                type='text'
                name='email'
                value={user.email}
                onChange={e => setEmail(e.target.value)}
                maxLength='38'
              />
            ) : (
              <i>{props.auth.userEmail}</i>
            )}
          </UserRowStyled>
          <UserRowStyled>
            <Button
              text={emailChange ? t('Uložiť') : t('Zmeniť e-mail')}
              type={emailChange ? 'greenFull' : 'blueFull'}
              onClick={
                emailChange
                  ? () => authUpdate('email')
                  : () => setEmailChange(true)
              }
              icon={emailChange ? 'save' : 'edit'}
            />
            {emailChange && (
              <Button
                text={t('Zrušiť')}
                type='redFull'
                onClick={() => setEmailChange(false)}
                icon='cancel'
              />
            )}
          </UserRowStyled>

          {passwordChange && (
            <UserRowStyled>
              <Input
                type='password'
                name='password'
                value={user.password}
                onChange={e => setPassword(e.target.value)}
                maxLength='12'
              />
            </UserRowStyled>
          )}

          <UserRowStyled>
            <Button
              text={passwordChange ? t('Uložiť') : t('Zmeniť heslo')}
              type={passwordChange ? 'greenFull' : 'blueFull'}
              onClick={
                passwordChange
                  ? () => authUpdate('password')
                  : () => setPasswordChange(true)
              }
              icon={passwordChange ? 'save' : 'edit'}
            />
            {passwordChange && (
              <Button
                text={t('Zrušiť')}
                type='redFull'
                onClick={() => setPasswordChange(false)}
                icon='cancel'
              />
            )}
          </UserRowStyled>
        </Box>
      )}
    </BoxStyled>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Settings);

const BoxStyled = styled(Box)`
  padding: 1rem;
`;

const ValueStyled = styled.span`
  color: #ff7870;
  font-weight: 700;
`;
const UserRowStyled = styled(Row)`
  padding: 5px 10px;
  min-height: 40px;
  align-items: center;
`;
const UserLabelStyled = styled(Row)`
  padding: 1rem;
  min-width: 16rem;
  align-items: center;
`;
const HighlightStyled = styled(Row)`
  padding: 0.5rem;
  color: #eeff8b;
  font-size: 2rem;
  font-weight: 500;
`;
const IMGStyled = styled.img`
  border: 0;
  outline: none;
  width: 10rem;
`;
