import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { mediaQueries } from 'common/theme';
import { Icons } from 'common/Icons';
import Button from 'components/UI/Button';
import Jersey from 'components/Jersey';
import { Box, Row } from 'components/BasicComponents';

const Message = ({
  data,
  playerID,
  canEdit,
  blueButton,
  redButton,
  isEditable,
  editMessage,
}) => {
  const { t } = useTranslation();

  const { name, surname, text, date, team, playerID: msgPlayerID } = data;
  const isMyMessage = playerID === msgPlayerID;
  const showButtons = canEdit || isMyMessage;

  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getDate();

  const newDate = `${day}. ${month}. ${year}`;
  const HH = new Date(date).getHours();
  const MM = new Date(date).getMinutes();
  const SS = new Date(date).getSeconds();

  return (
    <MessageStyled>
      <WrapperStyled>
        <Avatar isMyMessage={isMyMessage}>{`${name
          .toUpperCase()
          .slice(0, 1)}${surname.toUpperCase().slice(0, 1)}`}</Avatar>
        <TextStyled>
          {isEditable ? (
            <TextareaStyled
              placeholder={t('Text správy')}
              value={text}
              onChange={e => editMessage(e.target.value)}
            />
          ) : (
            text
          )}
        </TextStyled>
        {showButtons && (
          <ButtonWrapper>
            <Button
              onClick={blueButton}
              type={isEditable ? 'greenFull' : 'blue'}
              icon={isEditable ? 'save' : 'edit'}
              py='2rem'
              px='1rem'
            />
            <Button
              onClick={redButton}
              type={isEditable ? 'red' : 'red'}
              icon={isEditable ? 'cancel' : 'delete'}
              py='2rem'
              px='1rem'
            />
          </ButtonWrapper>
        )}
      </WrapperStyled>
      <DetailsStyled>
        <RowStyled>
          <JerseyStyled size='1.1rem' type={team === 'red' ? 'red' : ''} />
          <NameStyled isMyMessage={isMyMessage}>{`${surname} ${name.slice(
            0,
            1
          )}.`}</NameStyled>
        </RowStyled>
        <RowStyled>
          <i className={Icons.calendar} /> {newDate}{' '}
          <i className={Icons.clock} /> {HH}:{MM}:{SS}
        </RowStyled>
      </DetailsStyled>
    </MessageStyled>
  );
};

export default Message;

const MessageStyled = styled(Box)`
  width: 100%;
  padding: 1rem;
  margin: 0.3rem 0;
  &:not(:first-of-type) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
`;
const ButtonWrapper = styled(Row)`
  @media ${mediaQueries.m} {
    flex-direction: column;
  }
`;
const WrapperStyled = styled(Row)`
  align-items: flex-start;
`;
const TextStyled = styled(Box)`
  font-size: 1.5rem;
  color: #fff;
  padding: 0 1rem;
  width: 100%;
`;
const RowStyled = styled(Row)`
  padding: 0.5rem;
  align-items: center;
  & i {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
`;
const NameStyled = styled(Row)`
  color: ${props => (props.isMyMessage ? '#fff' : '')};
`;
const DetailsStyled = styled(Row)`
  font-size: 1.2rem;
  color: gray;
  padding: 0.1rem;
`;
const JerseyStyled = styled(Jersey)`
  margin-right: 0.5rem;
`;
const Avatar = styled(Box)`
  min-width: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  background: rgba(255, 255, 255, 0.1);
  color: ${props =>
    props.isMyMessage ? props.theme.colors.red : 'rgba(255, 255, 255, 0.2)'};
  border: 2px solid
    ${props =>
      props.isMyMessage ? props.theme.colors.red : 'rgba(255, 255, 255, 0.2)'};
  font-weight: 600;
  font-size: 3.2rem;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

const TextareaStyled = styled.textarea`
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  outline: none;
  padding: 1rem;
  color: white;
  font-family: 'BHLPrompt', calibri, tahoma;
  font-size: 1.5rem;
  border-radius: 1rem;
  width: 100%;
  min-width: 25rem;
  max-width: 100%;
  min-height: 8rem;
`;
