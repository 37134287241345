import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import axios from 'common/axios';
import {
  API_KEY,
  AUTH_URL,
  SLUG_PASSWORD_RESET,
} from 'common/axiosSettings.js';
import * as actionCreators from 'store/actions/index';
import { isValidEmail } from 'common/utils';
import Backdrop from 'components/UI/Backdrop';
import Loading from 'components/UI/Loading';
import { Row, Box } from 'components/BasicComponents';

import RegistrationContent from './RegistrationContent';
import LoginContent from './LoginContent';
import success from 'assets/images/success.svg';

const LoginScreen = ({
  signInToggler,
  closeLoginScreen,
  auth,
  postRegAuth,
  token,
  openedLoginScreen,
  loading,
  error,
  regSuccess,
  signIn,
  regSuccessMessage,
  afterReg,
}) => {
  const { t } = useTranslation();

  const [loginScreenMessage, setLoginScreenMessage] = useState('');
  const [passwordReset, setPasswordReset] = useState(false);

  const [user, setUser] = useState({
    email: '',
    password: '',
    name: '',
    surname: '',
    defaultTeam: '',
  });

  const beforeSignInToggler = () => {
    setLoginScreenMessage('');
    signInToggler();
  };

  const closeLoginScreenFunc = () => {
    setLoginScreenMessage('');
    closeLoginScreen();
  };

  const changeHandler = e => {
    if (e.target.name === 'email') {
      e.target.value = e.target.value.toLowerCase();
    }
    setUser(user => ({ ...user, [e.target.name]: e.target.value }));
  };

  const validationCheck = () => {
    if (!isValidEmail(user.email)) {
      setLoginScreenMessage(t('Zadaný e-mail nie je správny'));
      return false;
    }
    if (user.password.trim().length < 6) {
      setLoginScreenMessage(t('Heslo musí mať aspoň 6 znakov'));
      return false;
    }
    return true;
  };

  const validationRegCheck = () => {
    if (
      user.name.trim() === '' ||
      user.surname.trim() === '' ||
      !user.defaultTeam
    ) {
      setLoginScreenMessage(t('Musíš vyplniť všetky údaje'));
      return false;
    }
    return true;
  };

  const postAuth = (e, type) => {
    e.preventDefault();
    if (validationCheck()) {
      const typePost = type === 'signUp' ? 'signUp' : 'signIn';
      setLoginScreenMessage('');
      setUser(user => ({ ...user, email: '', password: '' }));
      auth({
        email: user.email,
        password: user.password,
        type: typePost,
      });
    }
  };

  const postRegAuthFunc = e => {
    e.preventDefault();
    if (validationRegCheck()) {
      postRegAuth({
        name: user.name,
        surname: user.surname,
        defaultTeam: user.defaultTeam,
      });
      setUser(user => ({
        ...user,
        name: '',
        surname: '',
        defaultTeam: '',
      }));
      setLoginScreenMessage('');
    }
  };

  const passwordResetF = () => {
    if (user.email.trim() === '') {
      setLoginScreenMessage(t('Zadaj tvoj e-mail'));
      return false;
    }
    if (!isValidEmail(user.email)) {
      setLoginScreenMessage(t('Zadaný e-mail nie je správny'));
      return false;
    }
    setLoginScreenMessage('');
    setPasswordReset(true);
    const data = {
      email: user.email,
      requestType: 'PASSWORD_RESET',
    };
    const url = `${AUTH_URL}${SLUG_PASSWORD_RESET}${API_KEY}`;

    axios
      .post(url, data)
      .then(response => {
        setLoginScreenMessage(t('Bol ti odoslaný e-mail na obnovu hesla'));
      })
      .catch(error => {
        let errorMessage = t('Nevyšlo to, skús to o chvíľu');
        if (error?.response?.data?.error?.message === 'EMAIL_NOT_FOUND') {
          errorMessage = t('Účet s takýmto emailom neexistuje');
        }
        setLoginScreenMessage(errorMessage);
        setPasswordReset(false);
      });
  };

  const extContent = afterReg ? (
    <RegistrationContent
      user={user}
      changeHandler={changeHandler}
      postRegAuth={postRegAuthFunc}
      message={loginScreenMessage}
      errorMessage={error}
    />
  ) : (
    <LoginContent
      signIn={signIn}
      user={user}
      changeHandler={changeHandler}
      postAuth={postAuth}
      passwordReset={passwordReset}
      passwordResetF={passwordResetF}
      beforeSignInToggler={beforeSignInToggler}
      message={loginScreenMessage}
      errorMessage={error}
      regSuccessMessage={regSuccessMessage}
    />
  );

  const content =
    (token && !afterReg) || (regSuccess && !afterReg) ? (
      <SuccessStyled />
    ) : (
      extContent
    );

  return (
    <>
      <Backdrop
        onClick={afterReg ? null : closeLoginScreenFunc}
        isOpened={openedLoginScreen}
        isLoginBackdrop
      />
      <LoginScreenStyled
        onClick={afterReg ? null : closeLoginScreenFunc}
        isOpened={openedLoginScreen}
      >
        <LoginBlockStyled onClick={e => e.stopPropagation()}>
          {loading ? (
            <LoadingWrapperStyled>
              <Loading />
            </LoadingWrapperStyled>
          ) : (
            content
          )}
        </LoginBlockStyled>
      </LoginScreenStyled>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    openedLoginScreen: state.auth.openedLoginScreen,
    loading: state.auth.loading,
    error: state.auth.errorMessage,
    regSuccess: state.auth.regSuccess,
    signIn: state.auth.signIn,
    regSuccessMessage: state.auth.regSuccessMessage,
    afterReg: state.auth.afterReg,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signInToggler: () => dispatch(actionCreators.signInToggler()),
    closeLoginScreen: () => dispatch(actionCreators.closeLoginScreen()),
    auth: userData => dispatch(actionCreators.auth(userData)),
    postRegAuth: userData => dispatch(actionCreators.postRegAuth(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

const LoginScreenStyled = styled(Box)`
  width: 100%;
  height: 50%;
  min-height: 350px;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.secondary};
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  padding: 5px;
  z-index: ${props => props.theme.zIndex.loginScreen};
  transition: all 0.2s;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  transform: ${props =>
    props.isOpened ? 'translateY(0)' : 'translateY(-110%)'};
`;

const LoginBlockStyled = styled(Box)`
  width: 30%;
  min-width: 200px;
  min-height: 310px;
  justify-content: flex-end;
  align-items: center;
`;

const SuccessStyled = styled(Row)`
  background: url(${success}) center center no-repeat;
  background-size: contain;
  width: 60%;
  height: 100%;
`;
const LoadingWrapperStyled = styled(Row)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 3.2rem;
`;
