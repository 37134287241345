import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import sk from './locales/sk.json';
// import cz from './locales/cz.json';

// i18n.use(initReactI18next).init({
//   debug: true,
//   fallbackLng: 'sk',
//   lng: 'sk',
//   load: 'all',
//   ns: false,
//   resources: {
//     sk: {
//       translation: {
//         ...sk,
//       },
//     },
//     cz: {
//       translation: {
//         ...cz,
//       },
//     },
//   },
//   react: { wait: true },
//   interpolation: { escapeValue: false },
// });

// eslint-disable-next-line
export const t = i18n;
