import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { AUTHORIZATION } from 'common/constants';
import { mediaQueries } from 'common/theme';
import { Icons } from 'common/Icons';

const resetScroll = () => {
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
};

const Menu = ({ auth }) => {
  const { t } = useTranslation();

  const isLoggedIn = !!auth?.token;
  const isPlayer = !!(
    isLoggedIn && auth?.authorization.includes(AUTHORIZATION.PLAYER)
  );
  const isOwner = !!(
    isLoggedIn && auth?.authorization.includes(AUTHORIZATION.OWNER)
  );
  const isAdmin = !!(
    isLoggedIn && auth?.authorization.includes(AUTHORIZATION.ADMIN)
  );
  const isApproved = isPlayer || isOwner || isAdmin;

  const NavLinkC = ({ icon, text, to }) => (
    <NavLink
      onClick={resetScroll}
      className={data => (data.isActive ? 'activeLink' : '')}
      to={to}
      exact
    >
      <IconWrapperStyled>
        <i className={Icons[icon]} />
      </IconWrapperStyled>
      <TextWrapperStyled>{text}</TextWrapperStyled>
    </NavLink>
  );

  return (
    <MenuStyled>
      <NavLinkC icon={'calendar'} text={t('ZÁPASY')} to='/matches' />
      {isApproved && (
        <>
          <NavLinkC icon={'user'} text={t('HRÁČI')} to='/users' />
          <NavLinkC icon={'stats'} text={t('ŠTATISTIKY')} to='/stats' />
          <NavLinkC icon={'gallery'} text={t('GALÉRIA')} to='/gallery' />
          {/* <NavLinkC icon={'puck'} text={t('BHL')} to='/matches' /> */}
          <NavLinkC icon={'usersettings'} text={t('ÚČET')} to='/account' />
        </>
      )}

      {(isOwner || isAdmin) && (
        <NavLinkC
          icon={'userssettings'}
          text={t('NASTAVENIA')}
          to='/settings'
        />
      )}
    </MenuStyled>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Menu);

const miniMenu = keyframes` 50% {
  transform: scale(1.8);
}`;

const MenuStyled = styled.nav`
  height: 100%;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-transform: uppercase;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }

  & a {
    height: 100%;
    text-decoration: none;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 1rem;
    transition: all 0.3s;
    position: relative;

    @media ${mediaQueries.l} {
      font-size: 1.2rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: ${props => props.theme.colors.tertiary};
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 0%;
      z-index: -1;
      transition: all 0.3s;
      @media ${mediaQueries.m} {
        bottom: 0;
        top: auto;
      }
    }

    @media ${mediaQueries.m} {
      font-size: 1.5rem;
      padding: 0.8rem;
      &.activeLink {
        animation: ${miniMenu} 0.2s linear;
      }
    }

    & i {
      transition: transform 0.4s;
      backface-visibility: hidden;
    }
    &:hover i {
      transform: scale(1.4);
    }
    &:hover,
    &:focus,
    &.activeLink {
      color: ${props => props.theme.colors.primary};
      outline: none;
      overflow: hidden;
      z-index: 4;
      &::after {
        height: 100%;
        bottom: 0;
      }
    }
    & .activeLink {
      font-weight: 800;
    }
    &.activeLink i {
      transform: scale(1.4);
    }
  }

  & p {
    width: 100%;
    color: ${props => props.theme.colors.menu};
    background: ${props => `${props.theme.colors.menu}22`};
    border-radius: 0 ${props => props.theme.radius.xs}
      ${props => props.theme.radius.xs} 0;
    font-weight: 800;
    font-size: 1.2rem;
    margin: 10px 0 5px 0;
    padding: 5px 10px;
  }
`;
const IconWrapperStyled = styled.div`
  display: none;
  @media ${mediaQueries.m} {
    display: flex;
  }
`;
const TextWrapperStyled = styled.div`
  display: flex;
  @media ${mediaQueries.m} {
    display: none;
  }
`;
