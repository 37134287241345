export const localStoragePersist = {
  set: (name, myObject) => localStorage.setItem(name, JSON.stringify(myObject)),
  get: name =>
    localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : {},
};

export const isValidEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const genericSort = (arr, dec, type, par) => {
  const getValue = (a, par) => {
    if (par === 'weekValue') {
      return a?.rows?.find(r => r?.type === 'date').value;
    }
    return a[par];
  };

  arr.sort((a, b) => {
    let A = par ? getValue(a, par) : a;
    let B = par ? getValue(b, par) : b;
    if (type === 'text') {
      return dec ? A.localeCompare(B) * -1 : A.localeCompare(B);
    } else if (type === 'num') {
      A = +A;
      B = +B;
    } else if (type === 'date') {
      A = new Date(`${A}T00:00:00Z`);
      B = new Date(`${B}T00:00:00Z`);
    }

    if (A > B) {
      return dec ? -1 : 1;
    }
    if (A < B) {
      return dec ? 1 : -1;
    }
    return 0;
  });

  return arr;
};

export const getDate = () => {
  const nowArr = new Date().toISOString().split('T')[0].split('-');

  const year = +nowArr[0];
  const month = +nowArr[1];
  const day = +nowArr[2];

  const yearPrev = year - 1;
  const monthPrev = month === 1 ? 12 : month - 1;

  return {
    year: year,
    month: month,
    day: day,
    monthPrev: monthPrev,
    yearPrev: yearPrev,
  };
};

export const isOldDay = date => {
  const now = new Date();
  return Date.parse(date) + 86400000 < Date.parse(now);
};

export const round = val => {
  if (!val || !val.length) {
    return 0;
  }

  return (
    Math.round(
      (val.reduce((acc, cur) => +acc + +cur, 0) + Number.EPSILON) * 100
    ) / 100
  );
};

export const formatISODate = isoDate => {
  if (!isoDate || !isoDate.includes('-')) {
    return null;
  }
  const dateArray = isoDate.split('-');
  return `${dateArray[2]}. ${dateArray[1]}. ${dateArray[0]}`;
};
export const formatISODateWithoutYear = isoDate => {
  if (!isoDate || !isoDate.includes('-')) {
    return null;
  }
  const dateArray = isoDate.split('-');
  return `${dateArray[2]}. ${dateArray[1]}.`;
};

export const formatName = ({ name, surname }) => {
  if (name.length && surname.length) {
    return `${surname} ${name.slice(0, 1)}.`;
  }
  return null;
};
