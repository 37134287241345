import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { Box } from 'components/BasicComponents';

export const VERSION = '1.0.18';

const Help = () => {
  const { t } = useTranslation();

  return (
    <HelpStyled>
      <strong>{t('Pripravované zmeny')}</strong>
      <ChangesStyled className='next'>
        {/* <li>moznost zrusit ucet</li> */}
        {/* <li>moznost pridat YT video</li> */}
        {/* <li>notifikacie o chate, novych zapasoch</li> */}
        {/* <li>rozdelenie obrana utok, posuvanie vertikalne v supiske</li> */}
        {/* <li>pridať pôvodnú galériu, bhl logá v krivkách</li> */}
        {/* <li>pridať graf formy hráčov</li> */}
        {/* <li>stahovat zapasy len raz</li> */}
        {/* <li>opraviť zobrazenie hráčov na mobile</li> */}
      </ChangesStyled>

      <strong>{t('Doterajšie zmeny')}</strong>
      <ChangesStyled className='past'>
        <Ver>{VERSION} (24.8. 2022)</Ver>
        <Imp>nahlasovanie pre tých, čo nevedia alebo nepôjdu</Imp>
        <Ver>1.0.9 (10.8. 2022)</Ver>
        <Fix>
          oprava chyby, keď sa používateľ bez ID nemohol zapísať na súpisku ak
          na nej už bol nejaký hosť
        </Fix>
        <Ver>1.0.2 (10.8. 2022)</Ver>
        <Fix>
          oprava chyby, keď používateľ bez ID mohol mazať a presúvať hosťov na
          súpiske
        </Fix>
        <Ver>1.0.1 (8.8. 2022)</Ver>
        <Imp>automatické práva hráča po registrácii</Imp>
        <Ver>1.0.0 (8.8. 2022)</Ver>
        <Imp>pre admina pridaný zoznam neregistrovaných hráčov</Imp>
        <Fix>prepočítavanie ceny bez brankárov</Fix>
        <New>pridávanie brankárov</New>
        <New>farebné 'G' podla toho, či sú na súpiskách brankári</New>
        <Ver>BETA 7 (6.8. 2022)</Ver>
        <Imp>grafické vylepšenia</Imp>
        <Ver>BETA 6 (5.8. 2022)</Ver>
        <Imp>grafické vylepšenia</Imp>
        <Ver>BETA 5 (3.8. 2022)</Ver>
        <Imp>možnosť editovať svoje správy v chate</Imp>
        <New>pridaný graf formy tímov</New>
        <Imp>pridané bydlisko hráčov</Imp>
        <Imp>0% graf červenou farbou</Imp>
        <Ver>BETA 4 (2.8. 2022)</Ver>
        <Imp>štatistika tímov</Imp>
        <Imp>grafické úpravy</Imp>
        <Imp>možnosť editovať minulé zápasy</Imp>
        <Ver>BETA 3 (1.8. 2022)</Ver>
        <Imp>možnosť zobraziť minulé zápasy</Imp>
        <Imp>možnosť mazať vlastné príspevky v chate</Imp>
        <Imp>zvýraznené vlastné príspevky v chate</Imp>
        <Imp>pridané výhry, prehry a remízy do štatistiky hráča</Imp>
        <Ver>BETA 2 (31.7. 2022)</Ver>
        <New>chat</New>
        <Imp>zoraďovanie jednotlivých stĺpcov v štatistike</Imp>
        <Imp>
          zvýraznenie vlastného mena, neregistrovaní hostia šedým písmom
        </Imp>
        <Imp>editácia gólov a asistencií</Imp>
        <Imp>meno pri góloch a asistenciách hostí</Imp>
        <New>editácia údajov hráča</New>
        <New>štatistiky</New>
        <Imp>zoraďovanie hráčov podľa priezviska</Imp>
        <Imp>zoraďovanie zápasov podľa dátumu</Imp>
        <Ver>BETA 1 (29.7. 2022)</Ver>
        <New>zoznam hráčov, nastavenia účtu</New>
        <New>pridávanie a mazanie gólov a asistencií</New>
        <New>presúvanie a mazanie hráčov na súpiske</New>
        <New>
          pridávanie na súpisku prihláseného používateľa, hosťa a hráča BHL
        </New>
        <New>súpiska a prehľad zápasu</New>
        <New>pridávanie, editácia zápasov</New>
      </ChangesStyled>
    </HelpStyled>
  );
};

export default Help;

const rotate = keyframes`
0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const HelpStyled = styled(Box)`
  font-size: 1.4rem;
`;

const ChangesStyled = styled.ul`
  padding-left: 10px;
  text-align: left;
  list-style-type: none;
  & li {
    padding: 5px;
  }
  & li::before {
    margin-right: 7px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
  }
  &.next li::before {
    content: '\f252';
    display: inline-block;
    animation: ${rotate} 4s infinite ease-in-out;
  }
`;

const Fix = styled.li`
  color: #ff7870;
  &::before {
    content: '\f7d9';
    color: #ff7870;
  }
`;
const Imp = styled.li`
  color: #82cbff;
  &::before {
    content: '\f102';
    color: #82cbff;
  }
`;

const New = styled.li`
  color: #a8e063;
  &::before {
    content: '\f0fe';
    color: #a8e063;
  }
`;

const Ver = styled.li`
  color: #fff;
  margin-top: 10px;
  font-weight: 800;
  font-size: 1.6rem;
`;
