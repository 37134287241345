import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { formatName, genericSort } from 'common/utils';
import Button from 'components/UI/Button';
import Jersey from 'components/Jersey';
import Loading from 'components/UI/Loading';
import { Box, Row, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';

import PlayerRow from 'pages/Matches/PlayerRow';

export const MatchDetail = ({
  data,
  addPlrHandler,
  deleteHandler,
  moveHandler,
  loading,
  canEdit,
  isPlayer,
  playerID,
  errorMessage,
  users,
  secondaryRegHandler,
  secondaryDeleteHandler,
}) => {
  const { t } = useTranslation();

  const [homeGuestPlayer, setHomeGuestPlayer] = useState('');
  const [awayGuestPlayer, setAwayGuestPlayer] = useState('');
  const [homeBHLPlayer, setHomeBHLPlayer] = useState({
    playerID: '',
    name: '',
  });
  const [awayBHLPlayer, setAwayBHLPlayer] = useState({
    playerID: '',
    name: '',
  });

  const [showSelect, setShowSelect] = useState(null);
  const [isGoalieHome, setIsGoalieHome] = useState(false);
  const [isGoalieAway, setIsGoalieAway] = useState(false);

  const { homeTeamRoster, awayTeamRoster, dontKnow, wontGo } = data;

  const changeHandler = (e, team) => {
    if (team === 'home') {
      setHomeGuestPlayer(e.target.value);
    } else {
      setAwayGuestPlayer(e.target.value);
    }
  };

  const homeTeamPlayers = homeTeamRoster.filter(plr => !plr.isGoalie);
  const homeTeamGoalie = homeTeamRoster.find(plr => plr.isGoalie);
  const awayTeamPlayers = awayTeamRoster.filter(plr => !plr.isGoalie);
  const awayTeamGoalie = awayTeamRoster.find(plr => plr.isGoalie);

  const totalPrice = data?.totalPrice || null;
  const playersTotal = homeTeamPlayers.length + awayTeamPlayers.length;
  const pricePerPlayer =
    totalPrice && playersTotal ? (totalPrice / playersTotal).toFixed(2) : null;

  const rate = 24.6;
  const totalCZK = (totalPrice * rate).toFixed(0);
  const perPlayerCZK = (pricePerPlayer * rate).toFixed(0);

  const goalieHandler = (e, team) => {
    team === 'home'
      ? setIsGoalieHome(e.target.checked)
      : setIsGoalieAway(e.target.checked);
  };

  const addPlayerHandler = (type, team, data, player) => {
    const goalieValue = team === 'home' ? isGoalieHome : isGoalieAway;
    addPlrHandler(type, team, data, player, goalieValue);
    team === 'home' ? setIsGoalieHome(false) : setIsGoalieAway(false);
  };

  const findName = playerID => {
    const wantedUser = users.find(user => user.playerID === playerID);
    return formatName(wantedUser);
  };

  const generateRegistration = team => (
    <RegistrationWrapperStyled>
      {isPlayer && (
        <>
          <RegistrationRowStyled>
            <Button
              icon='plus'
              type='greenFull'
              text={t('Zapísať sa')}
              onClick={() => addPlayerHandler('user', team)}
              mx='1rem'
            />
            <Input
              type='checkbox'
              checked={team === 'home' ? isGoalieHome : isGoalieAway}
              onChange={e => goalieHandler(e, team)}
              label={t('Ako brankár')}
            />
          </RegistrationRowStyled>
          <RegistrationRowStyled>
            <Button
              icon='cancel'
              type='redFull'
              text={t('Nepôjdem')}
              onClick={() => secondaryRegHandler('wontGo')}
              mx='1rem'
              my='1rem'
            />
            <Button
              icon='help'
              type='orangeFull'
              text={t('Neviem')}
              onClick={() => secondaryRegHandler('dontKnow')}
              mx='1rem'
              my='1rem'
            />
          </RegistrationRowStyled>

          <RegistrationRowStyled>
            {canEdit && (
              <Button
                icon={showSelect === team ? 'cancel' : 'plus'}
                type={showSelect === team ? 'red' : 'green'}
                text={t('BHL hráč')}
                onClick={() => setShowSelect(showSelect === team ? null : team)}
              />
            )}
          </RegistrationRowStyled>
        </>
      )}
      {canEdit && (
        <>
          {showSelect === team && (
            <RegistrationRowStyled>
              <Input
                type='select'
                value={
                  team === 'home'
                    ? homeBHLPlayer.playerID
                    : awayBHLPlayer.playerID
                }
                onChange={e =>
                  team === 'home'
                    ? setHomeBHLPlayer({
                        playerID: e.target.value,
                        name: findName(e.target.value),
                      })
                    : setAwayBHLPlayer({
                        playerID: e.target.value,
                        name: findName(e.target.value),
                      })
                }
                width='13.5rem'
                height='3rem'
              >
                <option value='' disabled>
                  {t('Vyber hráča')}
                </option>
                {genericSort(users, false, 'text', 'surname').map(player => (
                  <option value={player.playerID}>{formatName(player)}</option>
                ))}
              </Input>
              <Button
                icon='plus'
                type='green'
                text={t('Zapísať')}
                onClick={() => {
                  setShowSelect(null);
                  addPlayerHandler(
                    'bhlPlayer',
                    team,
                    null,
                    team === 'home' ? homeBHLPlayer : awayBHLPlayer
                  );
                }}
              />
            </RegistrationRowStyled>
          )}
          <RegistrationRowStyled>
            <Input
              type='text'
              value={team === 'home' ? homeGuestPlayer : awayGuestPlayer}
              onChange={e => changeHandler(e, team)}
              maxLength='18'
              placeholder={t('Priezvisko M.')}
              width='13.5rem'
              height='3rem'
            />
            <Button
              icon='plus'
              type='greenFull'
              text={t('Pridať hosťa')}
              onClick={() => {
                addPlayerHandler(
                  'guest',
                  team,
                  team === 'home' ? homeGuestPlayer : awayGuestPlayer
                );
                team === 'home'
                  ? setHomeGuestPlayer('')
                  : setAwayGuestPlayer('');
              }}
            />
          </RegistrationRowStyled>
        </>
      )}
    </RegistrationWrapperStyled>
  );

  const renderTeams = [
    {
      name: 'home',
      players: homeTeamPlayers,
      goalie: homeTeamGoalie,
    },
    {
      name: 'away',
      players: awayTeamPlayers,
      goalie: awayTeamGoalie,
    },
  ];

  return (
    <WrapperStyled>
      {errorMessage && <MessageBox>{errorMessage}</MessageBox>}
      <MatchDetailStyled>
        {renderTeams.map(team => {
          const renderRoster = [...team.players];
          if (team?.goalie) {
            renderRoster.push(team.goalie);
          }
          return (
            <TeamStyled key={team.name}>
              <Jersey size='6rem' type={team.name === 'home' ? '' : 'red'} />
              <BoxStyled>
                {loading ? (
                  <Loading />
                ) : (
                  renderRoster.map((player, i) => (
                    <PlayerRow
                      key={player.id}
                      data={player}
                      deleteHandler={() => deleteHandler(team.name, player.id)}
                      moveHandler={() => moveHandler(team.name, player)}
                      canEdit={canEdit}
                      isPlayer={isPlayer}
                      playerID={playerID}
                      num={i + 1}
                      home={team.name === 'home'}
                    />
                  ))
                )}
              </BoxStyled>
              {generateRegistration(team.name)}
            </TeamStyled>
          );
        })}
      </MatchDetailStyled>

      {pricePerPlayer && (
        <PriceWrapper>
          {t('Na 1 hráča')}:
          <PriceStyled>
            {pricePerPlayer} € / {perPlayerCZK} CZK
          </PriceStyled>
        </PriceWrapper>
      )}
      {totalPrice && (
        <PriceWrapper>
          {t('Celkovo')}:
          <PriceStyled>
            {totalPrice} € / {totalCZK} CZK
          </PriceStyled>
        </PriceWrapper>
      )}
      {!!dontKnow.length && (
        <DontKnowRowStyled>
          <BlockRowStyled>{t('Nevedia')}:</BlockRowStyled>
          <BlockRowStyled>
            {dontKnow.map(player => (
              <PlayerRow
                data={player}
                playerID={playerID}
                isPlayer={isPlayer}
                deleteHandler={() =>
                  secondaryDeleteHandler(player.id, 'dontKnow')
                }
                canEdit={canEdit}
                secondary
              />
            ))}
          </BlockRowStyled>
        </DontKnowRowStyled>
      )}
      {!!wontGo.length && (
        <WontGoRowStyled>
          <BlockRowStyled>{t('Nejdú')}:</BlockRowStyled>
          <BlockRowStyled>
            {wontGo.map(player => (
              <PlayerRow
                data={player}
                playerID={playerID}
                isPlayer={isPlayer}
                deleteHandler={() =>
                  secondaryDeleteHandler(player.id, 'wontGo')
                }
                canEdit={canEdit}
                secondary
              />
            ))}
          </BlockRowStyled>
        </WontGoRowStyled>
      )}
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Box)`
  width: 100%;
  align-items: center;
`;
const MatchDetailStyled = styled(Row)`
  width: 100%;
`;
const BoxStyled = styled(Box)`
  padding-top: 1rem;
`;
const TeamStyled = styled(Box)`
  width: 50%;
  align-items: center;
`;
const RegistrationWrapperStyled = styled(Box)`
  align-items: center;
  justify-content: center;
`;
const RegistrationRowStyled = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  flex-wrap: wrap;
`;
const BlockRowStyled = styled(Row)`
  width: 100%;
  flex-wrap: wrap;
  font-size: 1.4rem;
`;
const WontGoRowStyled = styled(Box)`
  width: 100%;
  background: #ff787030;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
`;
const DontKnowRowStyled = styled(Box)`
  width: 100%;
  background: #fff97130;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
`;
const PriceWrapper = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
`;
const PriceStyled = styled(Row)`
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-weight: 800;
`;
