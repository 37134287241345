import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { mediaQueries, sizes } from 'common/theme';
import { AUTHORIZATION } from 'common/constants';
import { Box } from 'components/BasicComponents';

import Matches from 'pages/Matches/Matches';
import Help from 'pages/myAccount/Help';

import Users from 'pages/owner/Users/Users';
import Stats from 'pages/owner/Stats/Stats';
import Gallery from 'pages/owner/Gallery/Gallery';
import BHL from 'pages/owner/BHL/BHL';

import Account from 'pages/myAccount/Settings/Settings';
import Settings from 'pages/owner/Settings/Settings';

const Content = ({ auth }) => {
  const isLoggedIn = !!auth?.token;

  const isPlayer = !!(
    isLoggedIn && auth?.authorization.includes(AUTHORIZATION.PLAYER)
  );
  const isOwner = !!(
    isLoggedIn && auth?.authorization.includes(AUTHORIZATION.OWNER)
  );
  const isAdmin = !!(
    isLoggedIn && auth?.authorization.includes(AUTHORIZATION.ADMIN)
  );
  const isApproved = isPlayer || isOwner || isAdmin;

  return (
    <ContentStyled>
      <Routes>
        <Route path='/' element={<Navigate to='/matches' />} />
        <Route path='/matches/*' element={<Matches />} />
        <Route path='/help/*' element={<Help />} />

        <Route path='/users' element={isApproved ? <Users /> : null} />
        <Route path='/stats' element={isApproved ? <Stats /> : null} />
        <Route path='/gallery' element={isApproved ? <Gallery /> : null} />
        <Route path='/bhl' element={isApproved ? <BHL /> : null} />

        <Route path='/account' element={isApproved ? <Account /> : null} />
        <Route
          path='/settings'
          element={isAdmin || isOwner ? <Settings /> : null}
        />

        <Route path='*' element={<Navigate to='/matches' />} />
      </Routes>
    </ContentStyled>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Content);

const ContentStyled = styled(Box)`
  width: 100%;
  max-width: ${sizes.xl};
  text-align: left;
  margin-top: 1rem;
  padding-top: ${props => props.theme.headerHeight.desktop};
  padding-bottom: 40px;
  @media ${mediaQueries.l} {
    padding-top: ${props => props.theme.headerHeight.compact};
  }
  @media ${mediaQueries.m} {
    padding-top: 0px;
    padding-bottom: calc(${props => props.theme.headerHeight.mobile} + 2rem);
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    font-family: 'BHLPrompt', calibri, tahoma;
    font-weight: 800;
    color: #fff;
  }
  & h1 {
    font-size: 2rem;
  }
  & h2 {
    font-size: 1.6rem;
  }
  & h3 {
    font-size: 1.4rem;
  }
  & h4 {
    font-size: 1.4rem;
  }
`;
