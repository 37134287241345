import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { mediaQueries } from 'common/theme';
import { Icons } from 'common/Icons';
import Button from 'components/UI/Button';
import { Row } from 'components/BasicComponents';
import Input from 'components/UI/Input';

const GoalRow = ({
  data,
  index,
  redButton,
  blueButton,
  isEditable,
  canEdit,
  team,
  sortedTeam,
  editIndexes,
  setEditIndexes,
  playerID,
}) => {
  const { t } = useTranslation();

  const { goal, assist } = data;

  const thisChangeHandler = (e, type) => {
    setEditIndexes(data => ({ ...data, [type]: +e.target.value }));
  };

  return (
    <GoalStyled>
      <NumStyled>
        {index + 1}
        <IStyled
          color={team === 'home' ? 'white' : '#DA0037'}
          className={Icons.puck}
        />
      </NumStyled>
      <WrapperStyled>
        <ColumnStyled className='goal'>
          {isEditable ? (
            <Input
              type='select'
              name='goal'
              value={editIndexes.goal}
              onChange={e => thisChangeHandler(e, 'goal')}
              width='13.5rem'
              height='3rem'
            >
              <option value={''}>{t('Gól')}</option>
              {sortedTeam.map((player, i) => (
                <option key={`${player.id}${i}`} value={i}>
                  {player.name}
                </option>
              ))}
            </Input>
          ) : (
            <NameRow
              className='name'
              isMyPlayer={playerID === goal?.playerID}
              isGuest={!goal?.playerID?.length}
            >
              {goal.name || t('Neznámy')}
            </NameRow>
          )}
        </ColumnStyled>
        <ColumnStyled>
          {isEditable ? (
            <Input
              type='select'
              name='assist'
              value={editIndexes.assist}
              onChange={e => thisChangeHandler(e, 'assist')}
              width='13.5rem'
              height='3rem'
            >
              <option value={''}>{t('Asistencia')}</option>
              {sortedTeam.map((player, i) => (
                <option key={`${player.id}${i}`} value={i}>
                  {player.name}
                </option>
              ))}
            </Input>
          ) : (
            <NameRow
              isMyPlayer={playerID === assist?.playerID}
              isGuest={!assist?.playerID?.length}
            >
              {assist?.name ? `(${assist.name})` : t('Bez asistencie')}
            </NameRow>
          )}
        </ColumnStyled>
      </WrapperStyled>
      {canEdit && (
        <ColumnStyled>
          <Button
            type={isEditable ? 'green' : 'blue'}
            icon={isEditable ? 'save' : 'edit'}
            onClick={blueButton}
          />
          <Button
            type='red'
            icon={isEditable ? 'cancel' : 'delete'}
            onClick={redButton}
          />
        </ColumnStyled>
      )}
    </GoalStyled>
  );
};

export default GoalRow;

const myTaskHighlight = keyframes`
  0% {
    color: #FFf;
  }
  50% {
    color: #DA0037;
  }
  100% {
    color: #FFf;
  }
`;

const GoalStyled = styled(Row)`
  width: 100%;
  min-height: 3rem;
  align-items: center;
`;
const NumStyled = styled(Row)`
  width: 1.5rem;
  color: gray;
  align-items: center;
  justify-content: flex-end;
  & i {
    margin-left: 0.5rem;
  }
  @media ${mediaQueries.m} {
    font-size: 1.3rem;
  }
  @media ${mediaQueries.s} {
    font-size: 1.1rem;
  }
  @media ${mediaQueries.xs} {
    display: none;
  }
`;
const ColumnStyled = styled(Row)`
  &.goal {
    font-weight: 600;
  }
`;
const IStyled = styled.i`
  color: ${props => props.color};
  @media ${mediaQueries.m} {
    font-size: 1.3rem;
  }
  @media ${mediaQueries.s} {
    font-size: 1.1rem;
  }
`;
const NameRow = styled(Row)`
  color: ${props => (props.isGuest ? '#888' : '#fff')};
  animation-name: ${props => (props.isMyPlayer ? myTaskHighlight : 'none')};
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  &.name {
    margin-right: 0.7rem;
  }
  @media ${mediaQueries.m} {
    font-size: 1.3rem;
    &.name {
      margin-right: 0rem;
    }
  }
  @media ${mediaQueries.s} {
    font-size: 1.1rem;
  }
`;
const WrapperStyled = styled(Row)`
  align-items: center;
  padding: 0.5rem;
  @media ${mediaQueries.s} {
    flex-direction: column;
    align-items: flex-start;
    margin: 0.5rem;
    padding: 0rem;
  }
`;
