import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { mediaQueries } from 'common/theme';
import Button from 'components/UI/Button';
import { Row } from 'components/BasicComponents';

const PlayerRow = ({
  data,
  moveHandler,
  deleteHandler,
  home,
  canEdit,
  isPlayer,
  playerID,
  num,
  secondary,
}) => {
  const [hasPaid, setHasPaid] = useState(false);

  const paidHandler = () => setHasPaid(hasPaid => !hasPaid);
  const isMyPlayer =
    isPlayer &&
    !!data?.playerID.length &&
    !!playerID.length &&
    playerID === data?.playerID;
  const showButton = canEdit || !!isMyPlayer;

  const isGuest = !data?.playerID?.length;
  const isGoalie = data?.isGoalie;

  return (
    <PlayerRowStyled isGoalie={isGoalie} home={home} secondary={secondary}>
      {showButton && (
        <Button
          type='redFull'
          icon='cancel'
          onClick={deleteHandler}
          py='1rem'
        />
      )}
      <NameRowStyled
        hasPaid={hasPaid}
        onClick={paidHandler}
        isMyPlayer={isMyPlayer}
        isGuest={isGuest}
      >
        {!secondary && (
          <NumStyled canEdit={canEdit}>{isGoalie ? 'G' : num}</NumStyled>
        )}
        {data.name}
      </NameRowStyled>
      {showButton && !secondary && (
        <Button
          type='blueFull'
          icon={home ? 'arrowright' : 'arrowleft'}
          onClick={moveHandler}
          py='1rem'
          num={isGoalie ? 'G' : num}
          left={home}
        />
      )}
    </PlayerRowStyled>
  );
};

export default PlayerRow;

const myTaskHighlight = keyframes`
  0% {
    color: #FFf;
  }
  50% {
    color: #DA0037;
  }
  100% {
    color: #FFf;
  }
`;

const PlayerRowStyled = styled(Row)`
  width: ${props => (props.secondary ? '' : '100%')};
  height: 3rem;
  margin: 0.4rem 0;
  flex-direction: ${props => (props.home ? 'row' : 'row-reverse')};
  align-items: center;
  justify-content: flex-end;
  background: ${props => (props.isGoalie ? '#00453d' : '')};
`;
const NameRowStyled = styled(Row)`
  width: 100%;
  background: ${props => (props.hasPaid ? '#69ffa961' : '')};
  /* color: ${props => (props.hasPaid ? '#000' : '')}; */
  align-items: center;
  font-weight: 700;
  margin: 0 0.7rem;
  padding: 0 0.7rem;
  border-radius: 0.4rem;
  cursor: pointer;
  color: ${props => (props.isGuest ? '#888' : '#fff')};
  animation-name: ${props => (props.isMyPlayer ? myTaskHighlight : 'none')};
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  @media ${mediaQueries.s} {
    margin: 0 0.4rem;
    padding: 0 0.4rem;
    font-size: 1.4rem;
  }
  @media (max-width: 360px) {
    margin: 0 0.2rem;
    padding: 0 0.2rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
`;
const NumStyled = styled(Row)`
  width: 2.5rem;
  margin-right: 0.5rem;
  font-weight: 400;
  color: gray;
  padding: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  @media ${mediaQueries.s} {
    width: 1.9rem;
    padding: 0.3rem;
    margin-right: 0.3rem;
  }
  @media ${mediaQueries.xs} {
    display: ${props => (props.canEdit ? 'none' : '')};
  }
`;
