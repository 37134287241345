import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

const RegistrationContent = ({
  user,
  changeHandler,
  postRegAuth,
  message,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <WrapperStyled
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {t('Údaje hráča')}
      <form>
        <WrapperStyled>
          <Input
            type='text'
            name='name'
            placeholder={t('Meno')}
            maxLength='15'
            value={user.name}
            onChange={e => changeHandler(e)}
            width='180px'
            height='35px'
          />

          <Input
            type='text'
            name='surname'
            placeholder={t('Priezvisko')}
            maxLength='20'
            value={user.surname}
            onChange={e => changeHandler(e)}
            width='180px'
            height='35px'
          />

          <Input
            type='select'
            name='defaultTeam'
            placeholder={t('Preferovaný tím')}
            value={user.defaultTeam}
            onChange={e => changeHandler(e)}
            width='180px'
            height='35px'
          >
            <option value='' disabled>
              {t('Preferovaný tím')}
            </option>
            <option value='white'>{t('Biely')}</option>
            <option value='red'>{t('Červený')}</option>
          </Input>

          {!!message && <MessageBox>{message}</MessageBox>}
          {errorMessage && <MessageBox>{errorMessage}</MessageBox>}

          <Button
            text={t('Dokončiť')}
            type='green'
            onClick={e => postRegAuth(e)}
            icon='success'
            my='.5rem'
          />
        </WrapperStyled>
      </form>
    </WrapperStyled>
  );
};

export default RegistrationContent;

const WrapperStyled = styled(Box)`
  align-items: center;
`;
