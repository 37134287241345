import * as actions from '../actions/actionTypes';

const initialState = {
  token: '',
  userEmail: '',
  userID: '',
  playerID: '',
  authorization: [],
  defaultTeam: '',
  expirationDate: '',

  name: '',
  surname: '',

  logoutMessage: '',
  errorMessage: '',
  regSuccessMessage: '',

  loading: false,
  regSuccess: false,
  afterReg: false,
  openedLoginScreen: false,
  signIn: true,

  reportCompleted: false,
  loginCompleted: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_MANY:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SIGN_IN_TOGGLER:
      return {
        ...state,
        signIn: !state.signIn,
        loading: false,
        afterReg: false,
        errorMessage: '',
        regSuccessMessage: '',
      };
    case actions.SIGN_IN_TOGGLER_AFTER_REG:
      return {
        ...state,
        signIn: !state.signIn,
        loading: false,
        afterReg: false,
        errorMessage: '',
      };
    default:
      return state;
  }
};

export default authReducer;
