import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'common/axios';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import * as actionCreators from 'store/actions';
import Loading from 'components/UI/Loading';
import { confirmModalAsync } from 'components/UI/ConfirmModal';
import { AUTHORIZATION } from 'common/constants';
import Button from 'components/UI/Button';
import { Box, Row, MessageBox } from 'components/BasicComponents';

import Message from 'pages/Matches/Message';

const Chat = props => {
  const { t } = useTranslation();

  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const token = props.auth.token;
  const playerID = props.auth.playerID || localStorage.getItem('playerID');
  // const isPlayer =
  //   token &&
  //   (props.auth.authorization?.includes(AUTHORIZATION.PLAYER) ||
  //     props.auth.authorization?.includes(AUTHORIZATION.ADMIN) ||
  //     props.auth.authorization?.includes(AUTHORIZATION.OWNER));
  const canEdit =
    token &&
    (props.auth.authorization?.includes(AUTHORIZATION.ADMIN) ||
      props.auth.authorization?.includes(AUTHORIZATION.OWNER));

  const loadChat = useCallback(() => {
    setLoading(true);
    axios
      .get(`chat.json?auth=${token}`)
      .then(response => {
        const newMessages = [];
        if (!response.data) {
          setLoading(false);
          return;
        } else {
          for (let message in response.data) {
            newMessages.unshift({
              name: response.data[message].name,
              surname: response.data[message].surname,
              playerID: response.data[message].playerID,
              text: response.data[message].text,
              date: response.data[message].date,
              team: response.data[message].team,
              id: message,
            });
          }
          setLoading(false);
          setMessages(newMessages);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    loadChat();
  }, [loadChat, token]);

  const addNewMessage = () => {
    setErrorMessage('');
    const notValid = !newMessage.trim().length;
    if (notValid) {
      setErrorMessage(t('Musíš vyplniť text'));
      return;
    }
    const data = {
      name: props.auth.name,
      surname: props.auth.surname,
      playerID: playerID,
      text: newMessage,
      team: props.auth.defaultTeam,
      date: new Date(),
    };
    axios.post(`chat.json?auth=${token}`, data).then(response => {
      loadChat();
    });
    setNewMessage('');
  };

  const editMessage = (id, value) => {
    const newMessages = cloneDeep(messages);
    const wantedIndex = newMessages.findIndex(msg => msg.id === id);
    newMessages[wantedIndex].text = value;
    setMessages(newMessages);
  };
  const postEditedHandler = async id => {
    const data = messages.find(msg => msg.id === id);
    delete data.id;

    axios.patch(`chat/${id}.json?auth=${token}`, data).then(response => {
      loadChat();
    });
    setEditable('');
  };
  const deleteHandler = async id => {
    const confirmResult = await confirmModalAsync({
      props: props,
      title: t('Odstrániť správu'),
      question: t('Naozaj chceš odstrániť túto správu?'),
    });

    if (confirmResult) {
      let newMessages = messages.filter(message => message.id !== id);
      setMessages(newMessages);
      axios.delete(`chat/${id}.json?auth=${token}`);
    }
  };

  const messagesList = loading ? (
    <Loading />
  ) : (
    messages.map(message => (
      <Message
        key={message.id}
        data={message}
        playerID={playerID}
        blueButton={() =>
          editable === message.id
            ? postEditedHandler(message.id)
            : setEditable(message.id)
        }
        redButton={() =>
          editable === message.id ? setEditable('') : deleteHandler(message.id)
        }
        canEdit={canEdit}
        isEditable={editable === message.id}
        editMessage={value => editMessage(message.id, value)}
      />
    ))
  );

  return (
    <ChatStyled>
      <h1>{t('Chatovica')}</h1>
      {errorMessage && <MessageBox>{errorMessage}</MessageBox>}
      <Row>
        <TextareaStyled
          placeholder={t('Text správy')}
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
        />
        <Button onClick={() => addNewMessage()} type='greenFull' icon='send' />
      </Row>
      <Box>
        {messagesList.length
          ? messagesList
          : t('Zatiaľ tu nie sú žiadne správy')}
      </Box>
    </ChatStyled>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: (component, componentProps, title) =>
      dispatch(actionCreators.openModal(component, componentProps, title)),
    closeModal: () => dispatch(actionCreators.closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

const ChatStyled = styled(Box)`
  width: 100%;
  padding: 2rem 0;
`;

const TextareaStyled = styled.textarea`
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  outline: none;
  padding: 1rem;
  color: white;
  font-family: 'BHLPrompt', calibri, tahoma;
  font-size: 1.5rem;
  border-radius: 1rem;
  width: 100%;
  min-width: 25rem;
  max-width: 100%;
  min-height: 8rem;
`;
