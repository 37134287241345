import * as actions from '../actions/actionTypes';

const initialState = {
  selectedComponent: null,
  selectedComponentProps: null,
  title: null,
  processing: false,
  modalText: '',

  message: null,
  error: false,
  autoHide: true,
};

const openModal = (state, component, componentProps = {}, title = null) => {
  return {
    ...state,
    selectedComponent: component,
    selectedComponentProps: componentProps,
    title: title,
  };
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return openModal(
        state,
        action.component,
        action.componentProps,
        action.title
      );
    case actions.CLOSE_MODAL:
      return initialState;
    case actions.START_PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case actions.STOP_PROCESSING:
      return {
        ...state,
        processing: false,
      };
    case actions.SET_FLASH_MESSAGE:
      return {
        ...state,
        ...action,
      };
    case actions.RESET_FLASH_MESSAGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default modalReducer;
