import React from 'react';
import styled from 'styled-components';

import { Row } from 'components/BasicComponents';
import { Icons } from 'common/Icons';

const SortIcon = ({ sortAsc }) => (
  <SortIconStyled>
    <i className={Icons[sortAsc ? 'sortUp' : 'sortDown']} />
  </SortIconStyled>
);

export default SortIcon;

const SortIconStyled = styled(Row)`
  color: ${props => props.theme.colors.tertiary};
  font-size: 1.7rem;
  position: absolute;
  bottom: 0px;
`;
