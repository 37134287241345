import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';

import axios from 'common/axios';
import { Row, Box } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';

const Settings = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newArena, setNewArena] = useState({
    name: '',
    arenaID: '',
  });
  const [arenas, setArenas] = useState([]);

  const token = props.auth.token;

  const loadArenas = useCallback(() => {
    setLoading(true);
    const queryParams = `?auth=${token}`;
    axios
      .get(`arenas.json${queryParams}`)
      .then(response => {
        if (!response.data) {
          setLoading(false);
          return;
        } else {
          const newArenas = [];
          for (let item in response.data) {
            newArenas.push({
              name: response.data[item].name,
              arenaID: response.data[item].arenaID,
              id: item,
            });
          }
          setArenas(newArenas);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [token]);

  useEffect(() => {
    loadArenas();
  }, [loadArenas]);

  const newArenaChangeHandler = e => {
    if (e.target.name === 'arenaID' && isNaN(e.target.value)) {
      return;
    }
    const newNewArena = cloneDeep(newArena);
    newNewArena[e.target.name] = e.target.value;
    setNewArena(newNewArena);
  };

  const addNewArenaHandler = () => {
    const notValid =
      newArena.name.trim().length < 3 || newArena.arenaID.length < 3;
    if (notValid) {
      setErrorMessage(
        t('Názov štadióna musí mať aspoň 3 znaky a jeho ID musí byť 3-ciferné')
      );
      return;
    }
    axios.post(`arenas.json?auth=${token}`, newArena).then(response => {
      loadArenas();
    });
    setNewArena({
      name: '',
      arenaID: '',
    });
  };

  return (
    <Box>
      <h1>{t('Nastavenia tímu')}</h1>
      <h2>{t('Zimné štadióny')}</h2>
      {loading && <Loading />}
      {arenas.map((arena, i) => (
        <ArenaStyled>
          <ArenaColumn>{i + 1}</ArenaColumn>
          <ArenaColumn>{arena.name}</ArenaColumn>
          <ArenaColumn>{arena.arenaID}</ArenaColumn>
        </ArenaStyled>
      ))}
      <Row>
        <Input
          name='name'
          type='text'
          maxLength='30'
          placeholder={t('Názov štadióna')}
          value={newArena.name}
          onChange={e => newArenaChangeHandler(e)}
          width='20rem'
        />
        <Input
          name='arenaID'
          type='text'
          maxLength='3'
          placeholder={t('ID')}
          value={newArena.arenaID}
          onChange={e => newArenaChangeHandler(e)}
          width='5rem'
        />
      </Row>

      <Button
        text={t('Pridať')}
        type='greenFull'
        onClick={addNewArenaHandler}
        icon='save'
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Settings);

const ArenaStyled = styled(Row)`
  padding: 5px;
  border-bottom: 1px solid #222;
`;
const ArenaColumn = styled(Row)`
  padding: 5px;
  background: red;
`;
