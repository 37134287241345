import React from 'react';
import { connect } from 'react-redux';
// import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { AUTHORIZATION } from 'common/constants';
import { Box, Row } from 'components/BasicComponents';
import * as actionCreators from 'store/actions/index';
import Button from './UI/Button';

const UserInfo = ({ auth, logout, openLoginScreen }) => {
  const { t } = useTranslation();
  const { authorization, token, logoutMessage } = auth;

  // const [lng, setLng] = useState('sk');

  // useEffect(() => {
  //   i18next.changeLanguage(lng, (error, t) => {
  //     if (error) return console.log('something went wrong loading', error);
  //   });
  // }, [lng]);

  let value = AUTHORIZATION.HOST;
  let privilege = t('Neschválený účet');

  if (authorization?.includes(AUTHORIZATION.PLAYER)) {
    privilege = t('Hráč');
    value = AUTHORIZATION.PLAYER;
  }
  if (authorization?.includes(AUTHORIZATION.ADMIN)) {
    privilege = t('Admin');
    value = AUTHORIZATION.ADMIN;
  }
  if (authorization?.includes(AUTHORIZATION.OWNER)) {
    privilege = t('Majiteľ');
    value = AUTHORIZATION.OWNER;
  }

  const isLoggedIn = token;

  return (
    <UserInfoWrapperStyled>
      {!!isLoggedIn && (
        <UserInfoStyled>
          <LoginInfoTextStyled value={value}>{privilege}</LoginInfoTextStyled>

          <LoginInfoTextStyled name>
            {`${localStorage.getItem('name')} ${localStorage.getItem(
              'surname'
            )}`}
          </LoginInfoTextStyled>
        </UserInfoStyled>
      )}

      {!!logoutMessage && (
        <UserInfoStyled>
          <LoginInfoTextStyled>
            <LogoutMessageStyled>{logoutMessage}</LogoutMessageStyled>
          </LoginInfoTextStyled>
        </UserInfoStyled>
      )}

      {/* {false && (
        <div className={classes.Language}>
          <select
            className={classes.LangSelect}
            value={lng}
            onChange={e => {
              setLng(e.target.value);
            }}
          >
            <option>sk</option>
            <option>cz</option>
            <option>en</option>
          </select>
        </div>
      )} */}

      <Button
        text={isLoggedIn ? t('Odhlásiť') : t('Prihlásiť')}
        type={isLoggedIn ? 'red' : 'green'}
        onClick={
          isLoggedIn
            ? () => logout(t('Odhlásenie bolo úspešné'))
            : openLoginScreen
        }
        icon={isLoggedIn ? 'logout' : 'login'}
        hideText
      />
    </UserInfoWrapperStyled>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: message => dispatch(actionCreators.logout(message)),
    openLoginScreen: () => dispatch(actionCreators.openLoginScreen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);

const UserInfoWrapperStyled = styled(Row)`
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const UserInfoStyled = styled(Box)`
  justify-content: center;
  align-items: center;
  @media ${mediaQueries.l} {
    display: none;
  }
`;
const LoginInfoTextStyled = styled(Row)`
  width: 100%;
  line-height: 1.3rem;
  margin: 1px 0;
  padding: 1px;
  text-align: center;
  font-size: 1rem;
  color: ${props =>
    props.name
      ? props.theme.colors.white
      : props.theme.colors.authorization[props.value]};
  justify-content: center;
  align-items: center;
  font-weight: 700;
`;

const LogoutMessageStyled = styled.span`
  color: ${props => props.theme.colors.tertiary};
  font-weight: 700;
  padding-right: 4px;
`;
