import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icons } from 'common/Icons';
import { mediaQueries } from 'common/theme';
import { Row } from 'components/BasicComponents';

const SwitchRow = ({ showRoster, setShowRoster }) => {
  const { t } = useTranslation();

  return (
    <SwitchRowStyled>
      <SwitchRowStyledHalf
        isActive={showRoster}
        onClick={() => setShowRoster(true)}
        isLeft
      >
        <i className={Icons.list} />
        {t('Súpiska')}
      </SwitchRowStyledHalf>
      <SwitchRowStyledHalf
        isActive={!showRoster}
        onClick={() => setShowRoster(false)}
      >
        <i className={Icons.puck} />
        {t('Góly')}
      </SwitchRowStyledHalf>
    </SwitchRowStyled>
  );
};

export default SwitchRow;

const SwitchRowStyled = styled(Row)`
  width: 100%;
  min-height: 4rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  border: 1px solid #ff7870;
  border-radius: 0.6rem;
  overflow: hidden;
  @media ${mediaQueries.s} {
    font-size: 1.5rem;
  }
`;
const SwitchRowStyledHalf = styled.button`
  background: transparent;
  width: 50%;
  height: 100%;
  padding: 0.5rem;
  transition: all 0.4s;
  z-index: 1;
  border: 0;
  outline: none;
  font-family: 'BHLPrompt', calibri, tahoma;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
  color: ${props => (props.isActive ? '#000' : '#fff')};
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    background: #ff7870;
    left: ${props => (props.isLeft ? '' : '0')};
    right: ${props => (props.isLeft ? '0' : '')};
    top: 0;
    bottom: 0;
    width: ${props => (props.isActive ? '100%' : '0')};
    height: 100%;
    z-index: -1;
    transition: all 0.5s ease-out;
  }
  & i {
    margin-right: 1rem;
  }
  &:hover {
    color: #000;
    &::after {
      width: 100%;
    }
  }
`;
