export const AUTHORIZATION = {
  OWNER: 'owner',
  ADMIN: 'admin',
  PLAYER: 'player',
  HOST: 'host',
};

export const ROUTES = {
  HOME: '/matches',
  USERS: '/users',
  PLAYERS: '/players',
  GALLERY: '/gallery',
  STATS: '/stats',
  BHL: '/bhl',
  MY_SETTINGS: `myAccount/settings`,
  SETTINGS: `/settings`,
  CHANGELOG: '/myAccount/help',
};

export const arenas = [
  { name: 'Uherský Brod', id: '001' },
  { name: 'Trenčín MG Rink', id: '002' },
  { name: 'Uherské Hradiště', id: '003' },
  { name: 'Trenčín Demitra', id: '004' },
];
