import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row } from 'components/BasicComponents';
import { Portal } from '../Portal';

const Backdrop = ({
  isLoginBackdrop,
  isTransparent,
  isOpened,
  onClick,
  children,
}) => {
  const background = isTransparent ? 'transparent' : 'rgba(0, 0, 0, 0.5)';

  return (
    <Portal type='backdrop-root'>
      <BackdropStyled
        onClick={onClick}
        isTransparent={isTransparent}
        isLoginBackdrop={isLoginBackdrop}
        isOpened={isOpened}
        background={background}
      >
        {children}
      </BackdropStyled>
    </Portal>
  );
};

export default Backdrop;

const BackdropStyled = styled(Row)`
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.isLoginBackdrop ? props.theme.colors.primaryLight : props.background};
  backdrop-filter: ${props =>
    props.isTransparent ? '' : 'blur(3px) grayscale(70%)'};
  transform: ${props =>
    props.isLoginBackdrop
      ? `translateY(${props.isOpened ? '0' : '-100'}%)`
      : ''};
  transition: all ${props => (props.isLoginBackdrop ? '0.2' : '0.1')}s;
  background-size: ${props => (props.isLoginBackdrop ? 'cover' : '')};
  opacity: ${props =>
    props.isLoginBackdrop ? '' : props.isOpened ? '1' : '0'};
  z-index: ${props =>
    props.isLoginBackdrop
      ? props.isOpened
        ? props.theme.zIndex.backdropLoginOpened
        : props.theme.zIndex.backdrop
      : props.isOpened
      ? props.theme.zIndex.backdropOpened
      : props.theme.zIndex.backdrop};

  @media ${mediaQueries.mMin} {
    display: ${props =>
      props.isLoginBackdrop || props.isTransparent ? '' : 'none'};
  }
`;
