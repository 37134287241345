import React from 'react';
import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';

import { Box } from 'components/BasicComponents';

const BHL = props => {
  const { t } = useTranslation();

  return (
    <Box>
      <h1>{t('BHL')}</h1>
      {/* images/logo/vector/nove_logo_bhl.pdf
      images/logo/vector/cierne_vector_bhl.pdf */}
    </Box>
  );
};

export default BHL;
