import React from 'react';
import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';
// import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import { Icons } from 'common/Icons';
// import { mediaQueries } from 'common/theme';
// import { genericSort } from 'common/utils';
import { Box } from 'components/BasicComponents';

const Gallery = props => {
  const { t } = useTranslation();

  // const [isOpen, setIsOpen] = useState(false);
  // const [territoryNum, setTerritoryNum] = useState(0);

  // const territoriesForGallery = genericSort([], false, 'num', 'num');

  // const selectedTerritory = territoriesForGallery.find(
  //   territory => territory.num === territoryNum
  // );
  // const prevTerritory =
  //   territoriesForGallery[
  //     territoriesForGallery.findIndex(
  //       territory => territory.num === territoryNum
  //     ) - 1
  //   ];
  // const prevNum =
  //   prevTerritory?.num ||
  //   territoriesForGallery[territoriesForGallery.length - 1]?.num;

  // const nextTerritory =
  //   territoriesForGallery[
  //     territoriesForGallery.findIndex(
  //       territory => territory.num === territoryNum
  //     ) + 1
  //   ];
  // const nextNum = nextTerritory?.num || territoriesForGallery[0]?.num;

  return (
    <Box>
      <h1>{t('Galéria')}</h1>
      {/* <a href='fkromeriz.php'>2. Medzinárodný</a>
      foto/kromeriz/1.jpg foto/kromeriz/male/1.jpg foto/kromeriz/male/19.jpg
      <a href='f7.php'>24.2. 2017</a>
      foto/7/1.jpg foto/7/male/1.jpg foto/7/male/51.jpg
      <a href='fpuchov.php'>Medzinárodný</a>
      foto/puchov/1.jpg foto/puchov/male/1.jpg foto/puchov/male/17.jpg */}
      {/* <ImgWrapperStyled
        onClick={() => {
          setIsOpen(true);
          setTerritoryNum('data.num');
        }}
      >
        <a
          href={'data.url'}
          target='_blank'
          rel='noreferrer noopener'
          onClick={e => e.stopPropagation()}
          download
        >
          <i className={Icons.download}></i>
        </a>
        <img alt={'data.label'} src={'data.thumbnail'} />
      </ImgWrapperStyled> */}
    </Box>
  );
};

export default Gallery;

// const ImgWrapperStyled = styled(Box)`
//   position: relative;
//   cursor: zoom-in;
//   & a {
//     background: ${props => props.theme.colors.black8};
//     color: rgb(201, 201, 201);
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     border: 0;
//     width: 3rem;
//     height: 3rem;
//     display: flex;
//     font-size: 1.6rem;
//     cursor: pointer;
//     justify-content: center;
//     align-items: center;
//     transition: all 0.5s;
//     @media ${mediaQueries.m} {
//       background: #25cc70;
//       color: #fff;
//     }
//   }
//   & a:hover {
//     background: #25cc70;
//     color: #fff;
//   }
// `;
