import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { Row, Box } from 'components/BasicComponents';
import Backdrop from './Backdrop';
import Button from './Button';

export const confirmModalAsync = async ({
  props,
  alertOnly,
  title,
  question,
  submitText,
  cancelText,
  content,
}) => {
  return new Promise((resolve, reject) => {
    props.openModal(
      ConfirmModal,
      {
        onSubmit: () => {
          props.closeModal();
          resolve(true);
        },
        handleCancel: () => {
          props.closeModal();
          resolve(false);
        },
        question,
        alertOnly,
        content,
        submitText,
        cancelText,
      },
      title
    );
  });
};

export const ConfirmModal = ({
  title,
  question,
  alertOnly,
  content,
  handleCancel,
  cancelText,
  onSubmit,
  submitText,
}) => {
  const { t } = useTranslation();

  return (
    <Backdrop isOpened isTransparent>
      <ModalStyled>
        {!alertOnly && <CloseButton onClick={handleCancel} />}
        <TitleStyled>{title}</TitleStyled>
        <p>{question}</p>
        {content}
        <ButtonWrapperStyled alertOnly={alertOnly}>
          {alertOnly ? (
            <Button
              text={submitText || t('Ok')}
              onClick={onSubmit}
              type='blueFull'
              icon={'success'}
            />
          ) : (
            <>
              <Button
                text={cancelText || t('Nie')}
                onClick={handleCancel}
                type='redFull'
                icon={'cancel'}
                my='1rem'
              />

              <Button
                text={submitText || t('Áno')}
                onClick={onSubmit}
                type='greenFull'
                icon={'success'}
                my='1rem'
              />
            </>
          )}
        </ButtonWrapperStyled>
      </ModalStyled>
    </Backdrop>
  );
};

const modal = keyframes`0% {
  transform: translateY(150vh) rotate(-90deg) scale(0);
}`;

const TitleStyled = styled(Box)`
  font-weight: bold;
`;

const ButtonWrapperStyled = styled(Row)`
  min-width: 190px;
  padding: 10px;
  width: 90%;
  flex-wrap: wrap;
  justify-content: ${props => (props.alertOnly ? 'center' : 'space-between')};
`;

const ModalStyled = styled(Box)`
  background: ${props => props.theme.colors.black8};
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  position: fixed;
  z-index: ${props => props.theme.zIndex.modal};
  margin: auto;
  opacity: 1;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.theme.radius.m};
  border: 1px solid ${props => props.theme.colors.white2};
  padding: 20px;
  text-align: center;
  backdrop-filter: saturate(140%) blur(10px);
  box-shadow: 0px 0px 25px ${props => props.theme.colors.black2};
  transform: translateY(0);
  transition: all 0.2s ease-out;
  animation: ${modal} 0.2s ease-out;
`;

const CloseButton = styled.button`
  background: transparent;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border: 0;
  outline: none;
  cursor: pointer;

  &::before {
    content: '+';
    transform: rotate(135deg);
    color: ${props => props.theme.colors.black2};
    font-size: 4rem;
    display: block;
    transition: all 0.2s ease-out;
  }
  &:hover::before {
    transform: rotate(45deg) scale(1.1);
    color: ${props => props.theme.colors.buttons.close.light};
  }
  &:active::before {
    transform: rotate(45deg) scale(0.9);
  }
`;
