import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

const LoginContent = ({
  signIn,
  user,
  changeHandler,
  postAuth,
  passwordReset,
  passwordResetF,
  beforeSignInToggler,
  message,
  errorMessage,
  regSuccessMessage,
}) => {
  const { t } = useTranslation();

  return (
    <WrapperStyled
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {signIn ? t('Prihlásiť sa') : t('Vytvoriť nový účet')}
      <form>
        <WrapperStyled>
          <InputWrapperStyled>
            <Input
              name='email'
              type='email'
              autoComplete={signIn ? 'current-password' : 'new-password'}
              placeholder={
                signIn ? t('Prihlasovací email') : t('Emailová adresa')
              }
              maxLength='35'
              value={user.email}
              onChange={e => changeHandler(e)}
              width='180px'
              height='35px'
            />
            <Input
              name='password'
              type='password'
              minLength='6'
              maxLength='15'
              autoComplete={signIn ? 'current-password' : 'new-password'}
              placeholder={signIn ? t('Hesielko') : t('Nové hesielko')}
              value={user.password}
              onChange={e => changeHandler(e)}
              width='180px'
              height='35px'
            />
          </InputWrapperStyled>
          {!!message && <MessageBox>{message}</MessageBox>}
          {!!errorMessage && <MessageBox>{errorMessage}</MessageBox>}
          {!!regSuccessMessage && (
            <MessageBox success>{regSuccessMessage}</MessageBox>
          )}
          <Button
            text={signIn ? t('Prihlásiť sa') : t('Vytvoriť účet')}
            type='green'
            onClick={
              signIn ? e => postAuth(e, 'signIn') : e => postAuth(e, 'signUp')
            }
            icon={signIn ? 'login' : 'plus'}
            my='.5rem'
          />
        </WrapperStyled>
      </form>

      {signIn && !passwordReset && (
        <Button
          text={t('Zabudol som heslo')}
          type='orange'
          onClick={passwordResetF}
          icon='unlock'
          my='.5rem'
        />
      )}
      <Button
        text={signIn ? t('Vytvoriť nový účet') : t('Už mám vytvorený účet')}
        type='blue'
        onClick={beforeSignInToggler}
        icon={signIn ? 'plus' : 'user'}
      />
    </WrapperStyled>
  );
};

export default LoginContent;

const WrapperStyled = styled(Box)`
  align-items: center;
`;
const InputWrapperStyled = styled(Box)`
  margin-bottom: 0.8rem;
`;
