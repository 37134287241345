export const Icons = {
  arrowup: 'fas fa-arrow-up',
  application: 'fas fa-file-upload',
  arrowdown: 'fas fa-arrow-down',
  arrowright: 'fas fa-arrow-right',
  administration: 'fas fa-edit',
  arrowleft: 'fas fa-arrow-left',

  bag: 'fas fa-briefcase',
  bagtime: 'fas fa-business-time',
  ban: 'fas fa-ban',
  book: 'fas fa-book',
  building: 'fas fa-building',
  buildingAlt: 'far fa-building',

  calendar: 'fas fa-calendar-alt',
  calendarday: 'fas fa-calendar-day',
  calendarweek: 'fas fa-calendar-week',
  calendarcheck: 'far fa-calendar-check',
  calculator: 'fas fa-calculator',
  cancel: 'fas fa-times',
  clipboard: 'fas fa-clipboard-check',
  clock: 'far fa-clock',
  cooperation: 'fas fa-street-view',

  delete: 'fas fa-trash-alt',
  door: 'fas fa-door-open',
  download: 'fas fa-cloud-download-alt',

  envelope: 'fas fa-envelope',
  envelopeList: 'fas fa-envelope-open-text',
  edit: 'fas fa-edit',

  female: 'fas fa-male',
  filter: 'fas fa-filter',

  gallery: 'fas fa-photo-video',
  globe: 'fas fa-globe-americas',
  group: 'fas fa-users',

  hamburger: 'fas fa-bars',
  hand: 'fas fa-hand-holding',
  help: 'far fa-question-circle',
  helmet: 'fas fa-hard-hat',
  history: 'fas fa-history',
  hide: 'far fa-eye-slash',
  home: 'fas fa-home',
  hourglass: 'fas fa-hourglass-end',

  info: 'fas fa-info',
  infocircle: 'fas fa-info-circle',

  list: 'fas fa-list-ol',
  login: 'fas fa-sign-in-alt',
  logout: 'fas fa-sign-out-alt',

  map: 'fas fa-map-marked-alt',
  mapmarker: 'fas fa-map-marker-alt',
  male: 'fas fa-male',
  medical: 'fas fa-notes-medical',
  minus: 'fas fa-minus',

  organizer: 'fas fa-user-shield',

  peoplearrows: 'fas fa-people-arrows',
  phone: 'fas fa-phone',
  print: 'fas fa-print',
  publishers: 'far fa-id-badge',
  plus: 'fas fa-plus',
  puck: 'fas fa-hockey-puck',

  random: 'fas fa-random',
  reset: 'fas fa-sync',

  sad: 'far fa-frown',
  save: 'far fa-save',
  send: 'far fa-paper-plane',
  select: 'fas fa-hand-pointer',
  settings: 'fas fa-tools',
  show: 'far fa-eye',
  smile: 'far fa-smile',
  sort: 'fas fa-sort',
  sortAZ: 'fas fa-sort-alpha-down',
  sortZA: 'fas fa-sort-alpha-down-alt',
  sortUp: 'fas fa-sort-up',
  sortDown: 'fas fa-sort-down',
  speaker: 'fas fa-user-tie',
  star: 'fas fa-star',
  stats: 'far fa-chart-bar',
  success: 'fas fa-check',

  travel: 'fas fa-suitcase-rolling',
  tractor: 'fas fa-tractor',
  truck: 'fas fa-truck-moving',

  unlock: 'fas fa-unlock-alt',
  user: 'fas fa-user',
  users: 'fas fa-user-friends',
  usernew: 'fas fa-user-plus',
  useredit: 'fas fa-user-edit',
  userdelete: 'fas fa-user-times',
  usersettings: 'fas fa-user-cog',
  userssettings: 'fas fa-users-cog',

  warning: 'fas fa-exclamation-triangle',
};
