import React from 'react';
import styled from 'styled-components';

import { Box } from 'components/BasicComponents';

const ColorCircle = ({
  value = 'draw',
  size = '2rem',
  textSize = '1.7rem',
  margin = '.2rem',
}) => {
  let color = '#FFC84B';
  let textColor = '#794b0e';
  let letter = 'R';
  if (value === 'win') {
    color = '#08EB5C';
    textColor = '#00702D';
    letter = 'V';
  }
  if (value === 'lost') {
    color = '#FF4240';
    textColor = '#81140F';
    letter = 'P';
  }

  return (
    <ColorCircleStyled
      size={size}
      textSize={textSize}
      margin={margin}
      color={color}
      textColor={textColor}
      isDraw={value === 'draw'}
    >
      {letter}
    </ColorCircleStyled>
  );
};

export default ColorCircle;

const ColorCircleStyled = styled(Box)`
  background: ${props => props.color};
  width: ${props => props.size};
  min-width: ${props => props.size};
  height: ${props => props.size};
  background: ${props => props.color};
  margin: ${props => props.margin};
  font-size: ${props => props.textSize};
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${props => props.textColor};
`;
