import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ThemeProvider } from 'components/ThemeProvider';
import * as actionCreators from 'store/actions/index';
import Header from 'components/Header';
import Content from 'components/Content';
import { Box } from 'components/BasicComponents';
import { Modal } from 'components/UI/Modal';

import LoginScreen from './LoginScreen';
import puckBack from 'assets/images/puck.png';

const App = ({ refreshAuth }) => {
  useEffect(() => {
    refreshAuth();
  }, [refreshAuth]);

  let installPrompt;

  // window.addEventListener('beforeinstallprompt', e => {
  //   e.preventDefault();
  //   installPrompt = e;
  //   return false;
  // });

  return (
    <BrowserRouter>
      <ThemeProvider>
        <AppStyled>
          <Modal />
          <LoginScreen />
          <Header install={installPrompt} />
          <Content />
        </AppStyled>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const AppStyled = styled(Box)`
  background: url(${puckBack});
  background-size: 0.8rem;
  width: 100%;
  height: 100vh;
  align-items: center;
`;

const mapDispatchToProps = dispatch => {
  return {
    refreshAuth: () => dispatch(actionCreators.refreshAuth()),
  };
};

export default connect(null, mapDispatchToProps)(App);
