import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AUTHORIZATION } from 'common/constants';
import Jersey from 'components/Jersey';
import { formatName, formatISODate } from 'common/utils';
import ProgressCircle from 'components/UI/ProgressCircle';
import { mediaQueries } from 'common/theme';
import { Row, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import SortIcon from './SortIcon';
import { Icons } from 'common/Icons';

const User = ({
  isEditable,
  data,
  edit,
  index,
  blueButton,
  redButton,
  errorMessage,
  isLabel,
  isStat,
  canEdit,
  setSort,
  sortAsc,
  sortParameter,
}) => {
  const { t } = useTranslation();

  const [type, setType] = useState(null);

  const timerHandler = type => {
    setType(type);
    setTimeout(() => setType(null), 1000);
  };

  const sortedColumns = [
    {
      name: 'surname',
      className: 'name',
      label: t('Hráč'),
    },
    {
      name: 'attendance',
      className: 'percentage',
      tooltip: t('% Účasť'),
      label: t('%'),
    },
    {
      name: 'gamesPlayed',
      className: 'stat',
      tooltip: t('Odohrané zápasy'),
      label: t('GP'),
    },
    {
      name: 'goals',
      className: 'stat',
      tooltip: t('Góly'),
      label: t('G'),
    },
    {
      name: 'assists',
      className: 'stat',
      tooltip: t('Asistencie'),
      label: t('A'),
    },
    {
      name: 'points',
      className: 'stat',
      tooltip: t('Body'),
      label: t('P'),
    },
    {
      name: 'pointsPerGame',
      className: 'stat',
      tooltip: t('Body na zápas'),
      label: t('P/GP'),
    },
    {
      name: 'wins',
      className: 'stat',
      tooltip: t('Asistencie'),
      label: t('W (%)'),
      hide: true,
    },
    {
      name: 'loses',
      className: 'stat',
      tooltip: t('Prehraté zápasy'),
      label: t('L (%)'),
      hide: true,
    },
    {
      name: 'draws',
      className: 'stat',
      tooltip: t('Remízy'),
      label: t('D (%)'),
      hide: true,
    },
  ];

  if (isLabel) {
    return isStat ? (
      <UserStyled isLabel isStat>
        <UserColumnStyled className='numStat'>#</UserColumnStyled>
        {sortedColumns.map(column => (
          <UserColumnStyled
            onClick={() => setSort(column.name)}
            onMouseEnter={() => timerHandler(column.name)}
            className={[
              column.className,
              type === column.name ? 'active' : '',
            ].join(' ')}
            hide={column?.hide}
            isLabel
            isStat
          >
            {column?.tooltip && (
              <ToolTip className='tooltip'>{column.tooltip}</ToolTip>
            )}
            {column.label}
            {sortParameter === column.name && <SortIcon sortAsc={sortAsc} />}
          </UserColumnStyled>
        ))}
      </UserStyled>
    ) : (
      <UserStyled isLabel>
        <UserColumnStyled className='num'>#</UserColumnStyled>
        <UserColumnStyled className='name'>
          {t('Meno a priezvisko')}
        </UserColumnStyled>
        <UserColumnStyled className='jersey'>{t('Dres')}</UserColumnStyled>
        <UserColumnStyled className='shoot'>{t('Streľba')}</UserColumnStyled>
        <UserColumnStyled className='birthDate'>
          {t('Dátum narodenia')}
        </UserColumnStyled>
        <UserColumnStyled className='town'>{t('Bydlisko')}</UserColumnStyled>
        <UserColumnStyled className='phone'>{t('tel. číslo')}</UserColumnStyled>
        <UserColumnStyled className='email'>{t('e-mail')}</UserColumnStyled>
        <UserColumnStyled className='id'>{t('ID')}</UserColumnStyled>
        <UserColumnStyled className='status' isLabel>
          {t('Práva')}
        </UserColumnStyled>
      </UserStyled>
    );
  }

  const authorization = [
    {
      id: AUTHORIZATION.PLAYER,
      label: t('Hráč'),
    },
    {
      id: AUTHORIZATION.ADMIN,
      label: t('Admin'),
    },
    {
      id: AUTHORIZATION.OWNER,
      label: t('Majiteľ'),
    },
  ];

  // const isLoggedIn = !!auth?.token;
  // const isPlayer = !!(
  //   isLoggedIn && auth?.authorization.includes(AUTHORIZATION.PLAYER)
  // );

  const home = isStat && data.defaultTeam === 'white';

  let statusIcon = 'ban';
  let color = 'red';
  if (data?.authorization === AUTHORIZATION.PLAYER) {
    statusIcon = 'user';
    color = '#fff';
  }
  if (data?.authorization === AUTHORIZATION.ADMIN) {
    statusIcon = 'usersettings';
    color = '#82CBFF';
  }
  if (data?.authorization === AUTHORIZATION.OWNER) {
    statusIcon = 'speaker';
    color = '#f67273';
  }

  const statUser = (
    <UserStyled isEditable={isEditable} isStat>
      <UserColumnStyled className='numStat'>
        <Jersey
          name={data?.surname.toUpperCase()}
          nameSize='.4rem'
          nameTop='.5rem'
          size='3rem'
          type={home ? null : 'red'}
          back
          num={data.jerseyNumber}
          margin='0 .5rem'
        />
      </UserColumnStyled>
      <UserColumnStyled className='name' isStat>
        {isEditable ? (
          <>
            <Input
              key='name'
              type='text'
              onChange={e => {
                edit(e);
              }}
              name='name'
              value={data?.name}
              placeholder={t('Meno')}
              height='3rem'
            />
            <Input
              key='surname'
              type='text'
              onChange={e => {
                edit(e);
              }}
              name='surname'
              value={data?.surname}
              placeholder={t('Priezvisko')}
              height='3rem'
            />
          </>
        ) : (
          formatName(data)
        )}
      </UserColumnStyled>
      <UserColumnStyled className='percentage' title={t('% Účasť')}>
        <ProgressCircle value={data?.attendance} size='.8rem' textSize='1rem' />
      </UserColumnStyled>
      <UserColumnStyled className='stat' title={t('Odohrané zápasy')}>
        {data.gamesPlayed}
      </UserColumnStyled>
      <UserColumnStyled className='stat' title={t('Góly')}>
        {data.goals}
      </UserColumnStyled>
      <UserColumnStyled className='stat' title={t('Asistencie')}>
        {data.assists}
      </UserColumnStyled>
      <UserColumnStyled className='stat' title={t('Body')}>
        {data.points}
      </UserColumnStyled>
      <UserColumnStyled className='stat' title={t('Body na zápas')}>
        {data.pointsPerGame}
      </UserColumnStyled>
      <UserColumnStyled hide className='stat green' title={t('Vyhraté zápasy')}>
        {data.wins}
      </UserColumnStyled>
      <UserColumnStyled hide className='stat red' title={t('Prehraté zápasy')}>
        {data.loses}
      </UserColumnStyled>
      <UserColumnStyled hide className='stat orange' title={t('Remízy')}>
        {data.draws}
      </UserColumnStyled>
    </UserStyled>
  );

  const defaultUser = (
    <UserStyled isEditable={isEditable}>
      <UserColumnStyled className='num'>{index + 1}</UserColumnStyled>
      <UserColumnStyled className='name'>
        {isEditable ? (
          <>
            <Input
              key='name'
              type='text'
              onChange={e => {
                edit(e);
              }}
              name='name'
              value={data?.name}
              placeholder={t('Meno')}
              height='3rem'
            />
            <Input
              key='surname'
              type='text'
              onChange={e => {
                edit(e);
              }}
              name='surname'
              value={data?.surname}
              placeholder={t('Priezvisko')}
              height='3rem'
            />
          </>
        ) : (
          `${data?.surname} ${data?.name}`
        )}
      </UserColumnStyled>

      <UserColumnStyled className='jersey'>
        {isEditable ? (
          <>
            <Input
              type='text'
              onChange={e => {
                edit(e);
              }}
              name='jerseyNumber'
              value={data?.jerseyNumber}
              placeholder={t('Dres')}
              maxLength='2'
              height='3rem'
              width='5rem'
            />
            <Input
              type='select'
              onChange={e => {
                edit(e);
              }}
              name='defaultTeam'
              value={data?.defaultTeam}
              height='3rem'
            >
              <option value=''>{t('Tím')}</option>
              <option value='white'>{t('Biely')}</option>
              <option value='red'>{t('Červený')}</option>
            </Input>
          </>
        ) : (
          <Jersey
            name={data?.surname.toUpperCase()}
            size='2.5rem'
            fontSize='1.1rem'
            nameSize='.4rem'
            nameTop='.5rem'
            type={data?.defaultTeam === 'white' ? null : 'red'}
            back
            num={data?.jerseyNumber}
            margin='0 .5rem'
          />
        )}
      </UserColumnStyled>
      <UserColumnStyled className='shoot'>
        {isEditable ? (
          <Input
            key='name'
            type='select'
            onChange={e => {
              edit(e);
            }}
            name='shoot'
            value={data?.shoot}
            height='3rem'
          >
            <option value=''>{t('Streľba')}</option>
            <option value='left'>{t('Ľavou')}</option>
            <option value='right'>{t('Pravou')}</option>
          </Input>
        ) : data?.shoot ? (
          data?.shoot === 'left' ? (
            t('Ľavou')
          ) : (
            t('Pravou')
          )
        ) : (
          ''
        )}
      </UserColumnStyled>
      <UserColumnStyled className='birthDate'>
        {isEditable ? (
          <Input
            key='name'
            type='date'
            onChange={e => {
              edit(e);
            }}
            name='birthDate'
            value={data?.birthDate}
            placeholder={t('Dátum narodenia')}
            height='3rem'
            width='12rem'
          />
        ) : (
          formatISODate(data?.birthDate)
        )}
      </UserColumnStyled>
      <UserColumnStyled className='town'>
        {isEditable ? (
          <Input
            name='town'
            placeholder={t('Bydlisko')}
            onChange={e => {
              edit(e);
            }}
            maxLength='22'
            type='text'
            value={data?.town}
            width='12rem'
            height='3rem'
          />
        ) : (
          data?.town
        )}
      </UserColumnStyled>

      <UserColumnStyled className='phone'>
        {isEditable ? (
          <Input
            key='phone'
            type='text'
            onChange={
              isEditable
                ? e => {
                    edit(e);
                  }
                : null
            }
            name='phone'
            value={data?.phone}
            placeholder={t('Tel. číslo')}
            maxLength='12'
            height='3rem'
            width='12rem'
          />
        ) : (
          data?.phone
        )}
      </UserColumnStyled>
      <UserColumnStyled className='email'>{data?.email}</UserColumnStyled>
      <UserColumnStyled className='id'>
        {isEditable ? (
          <Input
            type='text'
            onChange={e => {
              edit(e);
            }}
            name='playerID'
            value={data?.playerID}
            maxLength='3'
            placeholder='ID'
            height='3rem'
            width='5rem'
          />
        ) : (
          data?.playerID
        )}
      </UserColumnStyled>

      <UserColumnStyled className='status' color={color}>
        {isEditable ? (
          <Input
            type='select'
            onChange={e => {
              edit(e);
            }}
            name='authorization'
            value={data?.authorization}
            height='3rem'
            width='12rem'
          >
            <option value=''>{t('Neschválený')}</option>
            {authorization.map(itm => (
              <option key={itm.id} value={itm.id}>
                {itm.label}
              </option>
            ))}
          </Input>
        ) : (
          <i className={Icons[statusIcon]} />
        )}
      </UserColumnStyled>
      {canEdit && (
        <>
          {isEditable && errorMessage && (
            <MessageBox>{errorMessage}</MessageBox>
          )}
          <Button
            text={isEditable ? t('Uložiť') : t('Upraviť')}
            type='blueFull'
            onClick={blueButton}
            icon={isEditable ? 'save' : 'edit'}
          />
          <Button
            text={isEditable ? t('Zrušiť') : t('Odstrániť')}
            type='redFull'
            onClick={redButton}
            icon={isEditable ? 'cancel' : 'delete'}
          />
        </>
      )}
    </UserStyled>
  );

  return isStat ? statUser : defaultUser;
};

export default User;

const UserStyled = styled(Row)`
  background: ${props => (props.isEditable ? 'rgba(218, 218, 218, 0.2)' : '')};
  width: 100%;
  min-height: ${props => (props.isStat ? '4.5rem' : '3rem')};
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px dashed rgba(214, 214, 214, 0.3);
  font-size: 1.3rem;
  padding: 0 0.5rem;
  font-weight: ${props => (props.isLabel ? '800 !important' : '')};
  &:last-of-type {
    border-bottom: 0;
  }
  &:hover {
    background: ${props => (props.isLabel ? '' : 'rgba(161, 186, 202, 0.09)')};
  }
`;
const UserColumnStyled = styled(Row)`
  padding: 0px 0.5rem;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  position: relative;
  & .tooltip {
    display: none;
    z-index: 9;
    transition: all 0.4s;
  }
  &.active .tooltip {
    display: flex;
  }
  &.num {
    min-width: 3.5rem;
  }
  &.numStat {
    min-width: 5.2rem;
  }
  &.name {
    min-width: ${props => (props.isStat ? '10rem' : '15rem')};
    justify-content: flex-start;
    font-weight: 600;
    cursor: ${props => (props.isStat && props.isLabel ? 'pointer' : '')};
    &:hover {
      background: ${props =>
        props.isStat && props.isLabel ? props.theme.colors.sort : ''};
    }
  }
  &.stat {
    &.green {
      color: ${props => props.theme.colors.progressCompleted};
    }
    &.red {
      color: ${props => props.theme.colors.red};
    }
    &.orange {
      color: ${props => props.theme.colors.progress};
    }
    cursor: pointer;
    &:hover {
      background: ${props => (props.isLabel ? props.theme.colors.sort : '')};
    }
    padding: 0.5rem;
    min-width: 5rem;
    &:nth-of-type(2n) {
      background: rgba(255, 255, 255, 0.07);
      &:hover {
        background: ${props => (props.isLabel ? props.theme.colors.sort : '')};
      }
    }
  }
  &.percentage {
    cursor: pointer;
    width: 6rem;
    padding: 0.5rem;
    &:hover {
      background: ${props => (props.isLabel ? props.theme.colors.sort : '')};
    }
  }
  &.town {
    min-width: 14rem;
  }
  &.id {
    min-width: 4rem;
  }
  &.status {
    font-size: ${props => (props.isLabel ? '' : '2rem')};
    color: ${props => props.color};
    min-width: 5rem;
  }
  &.jersey {
    min-width: 5rem;
  }
  &.shoot {
    min-width: 7rem;
  }
  &.birthDate {
    min-width: 13rem;
  }
  &.phone {
    min-width: 10rem;
  }
  &.email {
    min-width: 19rem;
    font-size: 1.1rem;
    justify-content: flex-start;
  }
  @media ${mediaQueries.m} {
    &.num {
      display: none;
    }
    &.numStat {
      display: none;
    }
    &.stat {
      padding: 0rem;
      min-width: 3rem;
      width: 3rem;
      text-align: center;
    }
    &.percentage {
      min-width: 4rem;
      padding: 0.5rem;
      &:hover {
        background: ${props => (props.isLabel ? props.theme.colors.sort : '')};
      }
    }
    @media ${mediaQueries.s} {
      display: ${props => (props.hide ? 'none' : '')};
    }
  }
`;
const ToolTip = styled(Row)`
  color: #000;
  background: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  font-weight: 500;
  top: 120%;
  width: auto;
  min-width: 8rem;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
